import React from "react";
import Badge from 'react-bootstrap/Badge';

class AlogoLive extends React.Component {
    
    render(){
        var datasStats = this.props.alogoLive
        return(
            <>
            {datasStats.alogo.live.ecart1 && 
                <>
                {datasStats.alogo.live.ecart1.LiveValue>0 ? <>1: <Badge bg="danger">{'+'+datasStats.alogo.live.ecart1.LiveValue}</Badge></>
                :<>1: <Badge bg="success">{datasStats.alogo.live.ecart1.LiveValue}</Badge></>
                }                                        
                </>
                }
            {datasStats.alogo.live.ecart2 && 
                <>
                {datasStats.alogo.live.ecart2.LiveValue>0 ? <>&nbsp;&nbsp;&nbsp;&nbsp;2: <Badge bg="danger">{'+'+datasStats.alogo.live.ecart2.LiveValue}</Badge></>
                :<>&nbsp;&nbsp;&nbsp;&nbsp;2: <Badge bg="success">{datasStats.alogo.live.ecart2.LiveValue}</Badge></>
                }                                        
                </>
                }
            {datasStats.alogo.live.ecart3 && 
                <>
                {datasStats.alogo.live.ecart3.LiveValue>0 ? <>&nbsp;&nbsp;&nbsp;&nbsp;3: <Badge bg="danger">{'+'+datasStats.alogo.live.ecart3.LiveValue}</Badge></>
                :<>&nbsp;&nbsp;&nbsp;&nbsp;3: <Badge bg="success">{datasStats.alogo.live.ecart3.LiveValue}</Badge></>
                }                                        
                </>
                }    
            {datasStats.alogo.live.speed && 
                <>
                &nbsp;&nbsp;&nbsp;&nbsp;{datasStats.alogo.live.speed.LiveValue} km/h
                </>
                }                                                                    
            </>
        )}}
export default AlogoLive;

import React, { useState, useEffect, useRef } from "react";
import Badge from 'react-bootstrap/Badge';

function GiantRankingDR(props) {
    if (props){
        var idArena = props.idArena;
        var datas = props.datas;
        var datasTiming = props.datasTiming;
    }
    return (
        <>
            <div style={{ backgroundColor: "transparent",height:'100%',width:'100%',backgroundImage: "url(/img/giant_full/ranking.png)"}}>
                {datas.ranking &&
                <>
                <div className="giant_full-ranking-title">
                    <div className="giant_full-ranking-title-text">
                        {datas.competition.nameTV}
                    </div>
                </div>
                <div className="giant_full-ranking-lines">
                    {datas.ranking.map((d,index)=>{
                        if (index < 5){
                            return(
                                <div key={index} className="giant_full-ranking-line" style={{ backgroundRepeat:'no-repeat'}}>
                                    <div className="giant_full-ranking-line-rank">
                                        {d.results.entryRank?d.results.entryRank+'.':null}
                                    </div>
                                    <div className="giant_full-ranking-line-rider">
                                        {d.rider}
                                    </div>
                                    <div className="giant_full-ranking-line-horse">
                                        {d.horse}
                                    </div>
                                    <div className="giant_full-ranking-line-res">
                                        {d.results.total?d.results.total:null}
                                    </div>
                                    <div className="giant_full-ranking-flag">
                                        {d.nation &&
                                            <img alt="drapeau" width="150px" src={"/img/flags/"+d.nation+'.png'}/>
                                        }
                                    </div>
                                </div>        
                            )        
                        } else {
                            return (null)
                        }
                    })}
                </div> 
                </>   
                }    
            </div>
        </>
      );
  }

  export default GiantRankingDR;

import React, { useState, useEffect, useRef } from "react";
import Badge from 'react-bootstrap/Badge';
import {applyFontStretch} from '../../functions/strech.js'

function GiantRanking(props) {
    if (props){
        var idArena = props.idArena;
        var datas = props.datas;
        var datasTiming = props.datasTiming;
    }

    if (props.specificGFX){
        var urlGFX = '/img/events/'+idArena+'/giant_full_ranking/'
    } else {
        var urlGFX = '/img/giant_full/'
    }     
    useEffect(() => {
        applyFontStretch(".giant_full-ranking-title-text")        
    },[datas])

    return (
        <>
            <div style={{ backgroundColor: "transparent",height:'100%',width:'100%',backgroundImage: "url("+urlGFX+"ranking.png)"}}>
                {datas.ranking &&
                <>
                <div className="giant_full-ranking-title">
                    <div className="giant_full-ranking-title-text">
                        <p>{datas.competition.nameTV}</p>
                    </div>
                </div>
                <div className="giant_full-ranking-lines">
                    {datas.ranking.map((d,index)=>{
                        if (index < 5){
                            return(
                            <>
                            {d.results?
                                <div key={index} className="giant_full-ranking-line" style={{ backgroundRepeat:'no-repeat'}}>
                                    <div className="giant_full-ranking-line-rank">
                                        {d.results.entryRank?d.results.entryRank+'.':null}
                                    </div>
                                    <div className="giant_full-ranking-line-rider">
                                        {d.rider}
                                    </div>
                                    <div className="giant_full-ranking-line-horse">
                                        {d.horse}
                                    </div>
                                    <div className="giant_full-ranking-line-res">
                                        {d.results.totalPen}{d.results.totalTime && <> / {d.results.totalTime}</>}
                                    </div>
                                    <div className="giant_full-ranking-flag">
                                        {d.nation &&
                                            <img alt="drapeau" width="150px" src={"/img/flags/"+d.nation+'.png'}/>
                                        }
                                    </div>
                                </div>
                                :null}
                            {d.members?
                                <div key={index} className="giant_full-ranking-line" style={{ backgroundRepeat:'no-repeat'}}>
                                    <div className="giant_full-ranking-line-rank">
                                        {d.entryRank?d.entryRank+'.':null}
                                    </div>
                                    <div className="giant_full-ranking-line-rider">
                                        {d.name}
                                    </div>
                                    <div className="giant_full-ranking-line-horse">
                                        
                                    </div>
                                    <div className="giant_full-ranking-line-res">
                                        {d.total}{d.time && <> / {d.time}</>}
                                    </div>
                                    <div className="giant_full-ranking-flag">
                                        {d.nation &&
                                            <img alt="drapeau" width="150px" src={"/img/flags/"+d.nation+'.png'}/>
                                        }
                                    </div>
                                </div>
                                :null}
                            {d.res?
                                <div key={index} className="giant_full-ranking-line" style={{ backgroundRepeat:'no-repeat'}}>
                                    <div className="giant_full-ranking-line-rank">
                                        {d.entryRank?d.entryRank+'.':null}
                                    </div>
                                    <div className="giant_full-ranking-line-rider">
                                        {d.rider}
                                    </div>
                                    <div className="giant_full-ranking-line-horse">
                                        {d.horse}
                                    </div>
                                    <div className="giant_full-ranking-line-res">
                                        {d.total}
                                    </div>
                                    <div className="giant_full-ranking-flag">
                                        {d.nation &&
                                            <img alt="drapeau" width="150px" src={"/img/flags/"+d.nation+'.png'}/>
                                        }
                                    </div>
                                </div>
                                :null}
                            </>
                            )        
                        } else {
                            return (null)
                        }
                    })}
                </div> 
                </>   
                }    
            </div>
        </>
      );
  }

  export default GiantRanking;

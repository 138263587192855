import React, { useState, useEffect } from "react";
import Badge from 'react-bootstrap/Badge';

function MiniSo01(props) {
    const [ stylePath, setStylePath ] = useState(null);
    useEffect(() => {    
        if (stylePath){
            var head = document.head;
            var link = document.createElement("link");
        
            link.type = "text/css";
            link.rel = "stylesheet";
            link.href = stylePath;
        
            head.appendChild(link);    
            console.log(link)
            return () => { head.removeChild(link); }
        }
        
      }, [stylePath]);
      useEffect(() => {
        console.log('specific css '+props.specificCSS)
        if (props.specificCSS){
            var urlCSS = '/img/events/'+idArena+'/scb_mini/scb_mini_specific.css'
            setStylePath(urlCSS)
        }
      }, [props.specificCSS]);

    if (props){
        var idArena = props.idArena;
        var datas = props.datas;
        var datasTiming = props.datasTiming;
    }
    if (props.specificFlag){
        var urlFlag = '/img/events/'+idArena+'/giant/flags/'
    } else {
        var urlFlag = "/img/flags/"
    }        
    return (
        <>
            <div className="mini_full_mainbackground">
                {datas && datas.live ?
                <>
                <div className="mini_full-rider">
                    {datas.live.rider}
                </div>
                <div className="mini_full-flag">                    
                    {datas.live.nation &&
                        <img className="mini_full-drapeau" alt="drapeau" src={urlFlag+datas.live.nation+'.png'}/>
                    }
                </div> 
                <div className="mini_full-horse">
                    {datas.live.horse}
                </div>
                <div className="mini_full-chrono">
                    {datas.live.results.totalPen == 'RET' || datas.live.results.totalPen == 'EL' ?<></>:<>{datasTiming.countDown ? datasTiming.countDownValue : datasTiming.time}</>}
                </div>
                <div className="mini_full-points">
                    {datas.live.results.totalPen == 'RET' || datas.live.results.totalPen == 'EL' ?<>{datas.live.results.totalPen}</>:<>{datasTiming.totalFaults}</>}
                </div>
                {datas.live.results.entryRank?<>
                <div className="mini_full-rank">
                    {datas.live.results.extraPoints ?
                    <>
                        {datas.live.results.extraPoints}
                    </>:<>
                    {datas.live.results.entryRank ? <>{'Rk. '+datas.live.results.entryRank}</>:null}</>}
                </div>
                <div className="mini_line">
                    &nbsp;
                </div></>
                :null}

                <div className="mini_full-ecart">
                    {datas.timeDiff && 
                        <>
                        {datas.timeDiff > 0 ? 
                        <><Badge bg="danger" className="mini_badge_ecart">{'+'+datas.timeDiff}</Badge></>
                        :
                        <><Badge bg="success" className="mini_badge_ecart">{datas.timeDiff}</Badge></>} 
                        </>
                    }   
                </div>
                </>
                :
                <div className="mini_full-chrono">
                    {<>{datasTiming.countDown ? datasTiming.countDownValue : datasTiming.time}</>}
                </div>
}
            </div>
        </>
      );
  }

  export default MiniSo01;

import React from "react";
import moment from 'moment/min/moment-with-locales'

class Logo extends React.Component {
    constructor(props) {
        super(props);
        this.intervalTime = null;
        this.state = { datas: [],time:"99:99:99" };        
      }
    
    componentDidMount(){
        this.intervalTime = setInterval(this.afficheHeure,10)
    }

    afficheHeure=()=>{
        this.setState({time:moment().format('HH:mm:ss.S')})
    }


    componentWillUnmount(){
        console.log("CLOSE")
        clearInterval(this.intervalTime);
    }
    
    render() {
      return (
          <>
          <div style={{ backgroundColor:"#073E60",width:'100%',height:'100%' }}>
            <div className="title">
                <br/>
                <img alt="logo" width="600px" src="/img/wst.svg"/>
                <br/><br/>
                {this.props.no_data?<div className="data_waiting">Waiting for data...</div>:null}
                {this.props.type?<div className="data_waiting">{this.props.type}</div>:null}<br/>
            </div>
            <div className="time">
                {this.state.time}
            </div>
        </div>
        </>
      );
    }
  }

  export default Logo;

import React, { useState, useEffect, useRef } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from "react-bootstrap/Badge";
import moment from 'moment/min/moment-with-locales'
import {useInterval} from '../Functions'
import Logo from '../Logo'
import _ from 'lodash';
import './Judges.css'

function MainJudges(props) {
    if (props){
        var idArena = props.idArena;
    }
    const[startTick,setStartTick] = useState(null)
    const[timerTiming,setTimerTiming] = useState(null)
    const[timerRunning,setTimerRunning] = useState(false)
    const[lastPing,setLastPing] = useState(null)
    const[time,setTime] = useState("99:99:99");
    const [datas,setDatas] = useState();
    const [datasTiming,setDatasTiming] = useState({
      running:false,
      countDown:false,
      countDownValue: null,
      faults: null,
      totalFaults: null,
      fenceFaults: null,
      timeFaults: null,
      timeAdded: null,
      phase: 1,
      time:null
    });
    var _ws = null;
    var _url = process.env.REACT_APP_URL_WS
    const [isConnected,setIsConnected] = useState(false);
    

    useInterval(() => {
      setTime(moment().format('HH:mm:ss'));
    }, 100);

    useInterval(() => {
      var actual = Date.now()
      var reco 
      reco = false
      if (lastPing && (parseFloat(actual)-parseFloat(lastPing))>10000){
        reco = true
      }
      if (isConnected == false){
        reco = true
      }
      if (reco==true){
        if (_ws){
          _ws.close()
          _ws = null
        }
        console.log('reconnect')
        connectWS(idArena)
      }
    }, 5000);

    useEffect(() => { //initialisation
        connectWS(idArena)    
    },[]);

    const connectWS = (idArena) => {
      if (!idArena){return false;}
      const ws = new WebSocket(_url)
      _ws = ws
      ws.onopen = () => {
        console.log('Connected to the server')
        var message = {
          action: 'subscribe',
          payload: {
            topic: "dh-arena-"+idArena,
          },
        }
        message = JSON.stringify(message)
        ws.send(message)
        var message = {
            action: 'subscribe',
            payload: {
              topic: "chrono-arena-"+idArena,
            },
          }
        message = JSON.stringify(message)
        ws.send(message)
        setIsConnected (true)
      }
      // listen a message from the server
      ws.onmessage = (message) => {
        const jsonMessage = JSON.parse(message.data)
        const action = jsonMessage.action
        const payload = jsonMessage.payload
        switch (action) {
          case 'me':
            break
          case 'publish':
              if(idArena){
                switch (payload.topic) {
                  case 'chrono-arena-'+idArena:
                    output(payload.message);
                    break
                  case 'dh-arena-'+idArena:
                    if (!payload.message){
                      setDatas(null)
                    }else if(payload.message.timeDiff){
                      setDatas((datas) => {
                        return {...datas, timeDiff:fixedTo(payload.message.timeDiff,2) }
                      })  
                      setTimeout(()=>{
                        setDatas((datas) => {
                          return {...datas, timeDiff:null }
                        })
                      }, 8000);

                    } else {
                      setDatas((datas) => {
                        return Object.assign({}, datas, payload.message);
                      })  
                    }

                    break
                }  
              }
            break
          case 'ping':
            setLastPing(payload.message)
            break
          default:
            break
        }
      }
      ws.onerror = (err) => {
        console.log('unable connect to the server', err)    
        setIsConnected (false)
      }
      ws.onclose = () => {
        console.log('Connection is closed') 
        setIsConnected (false)
      }
    }
    const fixedTo = (number, digits) => {
      if(_.isFinite(number)){
        var k = Math.pow(10, digits);
        var value = Math.round(number * k) / k;
        return value.toFixed(digits);
      } else {
        return null;
      }
    }

    const output = (data)=>{
      //console.log(data);

      // If eliminated hide the clock
      //data.isEliminated = !!ShowJumpingEvent[data.fenceFaults];
  
      // Normalize
      data.faults = data.faults || 0;
      if(!data.timeAdded){
        data.timeAdded = null;
      }
      if(data.countDownValue){
        data.countDownValue = parseFloat(data.countDownValue, 10);
      }
      if (data.countDown && !data.running){
        start(Date.now() - (data.countDownValue * 1000));
      } else{
        if(data.running){
          data.time = fixedTo(parseFloat(data.time, 10) || 0, 1); 
          start(Date.now() - (parseFloat(data.time, 10) * 1000));
        } else {
          if(datasTiming.countDown && !data.countDown){
            data.countDown = true;
          }
          data.time = fixedTo(parseFloat(data.time, 10) || 0, 2);
          data.countDownValue = fixedTo(parseFloat(data.countDownValue, 10), 2);
          stop();
        }
      }
      setDatasTiming(data);
    }
    
    const start = (tick) => {
      setStartTick(tick);
      if (!timerRunning){
        setTimerRunning(true)
      }
    }

    const updateTime = () => {
      var t = (Date.now() - startTick) / 1000;
      if(datasTiming.countDown){
        setDatasTiming((datasTiming) => {
          return {...datasTiming, countDownValue: fixedTo(t, 1)}
        })
        if (datas && datas.timeDiff){
          setDatas((datas) => {
            return {...datas, timeDiff: null}
          })  
        }
      }else{
        if(datasTiming.running){
          setDatasTiming((datasTiming) => {
            return {...datasTiming, time: fixedTo(t, 1)}
          })
        }
      }
    }

    useEffect(() => {
      let tmr; 
      if (timerRunning){
        tmr = setInterval(updateTime, 100);
        setTimerTiming(tmr);  
      }
      return ()=>clearInterval(tmr)
    },[timerRunning,datasTiming]);
  
    const stop = () => {
      // clearInterval(timerTiming);
      // timerTiming = null;
      setTimerRunning(false)
      clearInterval(timerTiming)
      setTimerTiming(null)
    }

    const afficheHeure=()=>{
      setTime(moment().format('HH:mm:ss.S'))
    }

    //{datas?:<Logo no_data={true} />}
    return (
          <>
          {datas?
          <>
          <div style={{ backgroundColor:'#073E60',width:'100%',height:'100%' }}>
            <br/><br/>
            <div className="table_ranking">
            <Container fluid className="table-last">
              <Row >
                  <Col xs={5} className="last-title">   
                      Live
                  </Col>
                  <Col xs={2} className="last-title alignCenter">   
                      
                  </Col>
                  <Col xs={2} className="last-title alignCenter">   
                      
                  </Col>
                  <Col xs={2} className="last-title alignCenter">   
                      Total
                  </Col>
                  <Col xs={1} className="last-title alignCenter">   
                      
                  </Col>
              </Row>
              {datas.live ?
              <Row className="last-line-res">
                  <Col xs={1} className="line-res-cont-rank">   
                    <span className="line-res-rank"><> {datas.live.results.entryRank && <>{datas.live.results.entryRank}.</>} </></span><br/>
                    {datas.live.nation && <img alt="drapeau" width="100px" src={"/img/flags/"+datas.live.nation+'.png'}/>}
                  </Col>
                  <Col xs={4} className="line-res-cav-horse">
                      <> {datas.live.rider} </>
                      <br/>
                      <span className="line-res-horse">{datas.live.hnb} - {datas.live.horse} </span>
                  </Col>
                  <Col xs={2} className="line-res-cav-horse sub-score alignCenter">
                      {datas.live.results.time1 && 
                        <>
                        {datas.live.results.time1}
                          <br/>
                        {datas.live.results.fencePen1}+{datas.live.results.timePen1}={datas.live.results.totalPen1}
                        </>
                      }
                  </Col>
                  <Col xs={2} className="line-res-cav-horse sub-score alignCenter">
                      {datas.live.results.time2 &&
                      <>
                        {datas.live.results.time2}
                          <br/>
                        {datas.live.results.fencePen2}+{datas.live.results.timePen2}={datas.live.results.totalPen2}
                      </>
                      }
                  </Col>
                  <Col xs={2} className="line-res-cav-horse chrono alignCenter">
                    {datas && <>{datas.live && <>{datasTiming.countDown ? datasTiming.countDownValue : datasTiming.time}<br/>{datasTiming.totalFaults}</>}</>}                    
                  </Col>
                  <Col xs={1} className="line-res-cav-horse alignCenter">
                  {datas.timeDiff && 
                                    <>
                                    <div style={{fontSize:'35px',marginTop:'45px'}}>
                                    {datas.timeDiff > 0 ? 
                                    <><Badge bg="danger">{'+'+datas.timeDiff}</Badge></>
                                    :
                                    <><Badge bg="success">{datas.timeDiff}</Badge></>} 
                                    </div>
                                </>}

                  </Col>
              </Row>
              :<Row className="last-line-res"><Col xs={12}>&nbsp;<br/>&nbsp;</Col></Row>}
            </Container>
            </div>
            <br/><br/>

            <div className="table_ranking">
              <Container fluid className="table-last">
                <Row >
                    <Col xs={5} className="last-title">   
                        Last
                    </Col>
                    <Col xs={2} className="last-title alignCenter">   
                        
                    </Col>
                    <Col xs={2} className="last-title alignCenter">   
                        
                    </Col>
                    <Col xs={2} className="last-title alignCenter">   
                        Total
                    </Col>
                    <Col xs={1} className="last-title alignCenter">   
                        
                    </Col>
                </Row>
                {datas.last?
                <Row className="last-line-res">
                    <Col xs={1} className="line-res-cont-rank">   
                    <span className="line-res-rank">{datas.last.results.entryRank}.</span><br/>
                    {datas.last.nation && <img alt="drapeau" width="100px" src={"/img/flags/"+datas.last.nation+'.png'}/>}
                    </Col>
                    <Col xs={4} className="line-res-cav-horse">
                      {datas.last.rider}
                      <br/>
                      <span className="line-res-horse">{datas.last.hnb}-{datas.last.horse}</span>
                  </Col>
                  <Col xs={2} className="line-res-cav-horse sub-score alignCenter">
                      {datas.last.results.time1 && 
                        <>
                        {datas.last.results.time1}
                          <br/>
                        {datas.last.results.fencePen1}+{datas.last.results.timePen1}={datas.last.results.totalPen1}
                        </>}
                  </Col>
                  <Col xs={2} className="line-res-cav-horse sub-score alignCenter">
                      {datas.last.results.time2 && 
                            <>
                            {datas.last.results.time2}
                              <br/>
                            {datas.last.results.fencePen2}+{datas.last.results.timePen2}={datas.last.results.totalPen2}
                            </>}
                  </Col>
                  <Col xs={2} className="line-res-cav-horse chrono alignCenter">
                    {datas.last.results.totalTime}<br/>{datas.last.results.totalPen}
                  </Col>
                  <Col xs={1} className="line-res-cav-horse alignCenter">
                  </Col>
                </Row>
                :<Row className="last-line-res"><Col xs={12}>&nbsp;<br/>&nbsp;</Col></Row>}
              </Container>
            </div>
            <br/><br/>
            <div className="table_ranking">
              <Container fluid className="table-last">
                <Row >
                    <Col xs={12} className="last-title">   
                        Ranking
                    </Col>
                </Row>
                {datas && 
                  <>
                    {datas.ranking.map((d,index)=>{
                      if (index<3){
                            return(
                              <>
                                {d.results?
                                <Row className="last-line-res">
                                  <Col xs={1} className="line-res-cont-rank">   
                                  <span className="line-res-rank">{d.results.entryRank}.</span>
                                  </Col>
                                  <Col xs={7} className="line-res-cav-horse">
                                    {d.hnb} - {d.rider} - {d.horse} 
                                  </Col>
                                  <Col xs={1}>
                                    {d.nation && 
                                      <img alt="drapeau" width="100px" src={"/img/flags/"+d.nation+'.png'}/>
                                    }
                                  </Col>
                                  <Col xs={3} className="line-res-cav-horse alignCenter">
                                    {d.results.totalPen}{d.results.totalTime && <> / {d.results.totalTime}</>}
                                  </Col>
                                </Row>
                                :null}
                                {d.members?
                               <Row className="last-line-res">
                                <Col xs={1} className="line-res-cont-rank">   
                                <span className="line-res-rank">{d.entryRank}.</span>
                                </Col>
                                <Col xs={7} className="line-res-cav-horse">
                                  {d.name} 
                                </Col>
                                <Col xs={1}>
                                  {d.nation && 
                                    <img alt="drapeau" width="100px" src={"/img/flags/"+d.nation+'.png'}/>
                                  }
                                </Col>
                                <Col xs={3} className="line-res-cav-horse alignCenter">
                                  {d.total}{d.time && <> / {d.time}</>}
                                </Col>
                              </Row>
                                :null}
                                {d.res?
                               <Row className="last-line-res">
                                <Col xs={1} className="line-res-cont-rank">   
                                <span className="line-res-rank">{d.entryRank}.</span>
                                </Col>
                                <Col xs={7} className="line-res-cav-horse">
                                  {d.rider} 
                                </Col>
                                <Col xs={1}>
                                  {d.nation && 
                                    <img alt="drapeau" width="100px" src={"/img/flags/"+d.nation+'.png'}/>
                                  }
                                </Col>
                                <Col xs={3} className="line-res-cav-horse alignCenter">
                                  {d.total}
                                </Col>
                              </Row>
                                :null}
                          </>
                            )} else {
                              return(null)
                            }
                        })}
                  </>
                }
              </Container>
            </div>
            <br/><br/>
            <div className="judges-title">
                <Container fluid>
                    <Row>
                      <Col xs={1}>
                          Total
                      </Col>
                      <Col xs={1}>
                          Left
                      </Col>
                      <Col xs={1}>
                          Past
                      </Col>
                      <Col xs={2}>
                          Clear
                      </Col>
                      <Col xs={2}>
                          Allowed
                      </Col>
                      <Col xs={1}>
                          Rythm
                      </Col>
                      <Col xs={2}>
                          Finish
                      </Col>
                      <Col xs={2}>
                          Time
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={1}>
                          {datas && <>{datas.competition.total}</>}
                      </Col>
                      <Col xs={1}>
                          {datas && <>{datas.competition.left}</>}
                      </Col>
                      <Col xs={1}>
                          {datas && <>{datas.competition.past}</>}
                      </Col>
                      <Col xs={2}>
                          {datas && <>{datas.competition.clear} ({datas.competition.perc_clear}%)</>} 
                      </Col>

                      <Col xs={2}>
                          {datas && <>{datas.competition.timeAllowed}</>}
                      </Col>
                      <Col xs={1}>
                        {datas && <>{datas.competition.rythme}/h</>}
                      </Col>
                      <Col xs={2}>
                        {datas && <>{datas.competition.finish}</>}
                      </Col>
                      <Col xs={2}>
                          {time}
                      </Col>
                    </Row>

                </Container>
            </div>
          </div>
        </>
        :<Logo no_data={true} type={"Judges channel"} />}
        </>
      );
    
  }

  export default MainJudges;

import { forEach } from "lodash";

export const applyFontStretch = (className) => {
    const parents = document.querySelectorAll(className);
    if (!parents){return false;}
    parents.forEach((parent)=>{
        const child = parent.children[0];    
        if (!child){return false;}
        const parentWidth = parent.clientWidth;
        const childWidth = getTextWidth(child,getCanvasFont(child));
    
        let scale = 1;
        if (parentWidth<childWidth){
            scale = parentWidth/childWidth;
        } else {
            scale = 1;
        }
        // console.log(child)
        // console.log('parent: '+parentWidth)
        // console.log('child: '+childWidth)
        // console.log(scale)
        child.style.transformOrigin= '0% 50%';
        child.style.transform='scaleX('+ scale +')';
    })

}

function getTextWidth(child,font) {         
    
    const canvas = document.createElement("canvas"); 
    const context = canvas.getContext("2d"); 
    context.font = font;     
    const width = context.measureText(child.innerHTML).width; 
    const formattedWidth = Math.ceil(width); 
    return formattedWidth; 
} 

function getCssStyle(element, prop) {
    return window.getComputedStyle(element, null).getPropertyValue(prop);
}

function getCanvasFont(el = document.body) {
  const fontWeight = getCssStyle(el, 'font-weight') || 'normal';
  const fontSize = getCssStyle(el, 'font-size') || '16px';
  const fontFamily = getCssStyle(el, 'font-family') || 'Times New Roman';
  
  return `${fontWeight} ${fontSize} ${fontFamily}`;
}
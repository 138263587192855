import './App.css';
import React from "react";
import Table from './Table.js'
import Logo from './Logo.js'
import Test from './Test.js'
import { Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";

import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <>
    <Helmet>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link href="https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,slnt,wdth,wght,GRAD,XTRA,YOPQ,YTAS,YTDE,YTFI,YTLC,YTUC@8..144,-10..0,25..151,100..1000,-200..150,323..603,25..135,649..854,-305..-98,560..788,416..570,528..760&display=swap" rel="stylesheet" />

    </Helmet>
    <div className="fill-window">
      <div className="main">
          <Routes>
            <Route path="/" element={<Logo/>} />
            <Route path="/arena/:idArena/:tpl" element={<Table/>} />
            <Route path="/test" element={<Test/>} />
          </Routes>
      </div>
    </div>
    </>
  );
}

export default App;

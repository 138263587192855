import React, { useState, useEffect } from "react";
import Badge from 'react-bootstrap/Badge';

function MiniSo01(props) {
    const [ stylePath, setStylePath ] = useState(null);
    useEffect(() => {    
        if (stylePath){
            var head = document.head;
            var link = document.createElement("link");
        
            link.type = "text/css";
            link.rel = "stylesheet";
            link.href = stylePath;
        
            head.appendChild(link);    
            console.log(link)
            return () => { head.removeChild(link); }
        }
        
      }, [stylePath]);
      useEffect(() => {
        console.log('specific css '+props.specificCSS)
        if (props.specificCSS){
            var urlCSS = '/img/events/'+idArena+'/scb_mini_2/scb_mini_specific.css'
            setStylePath(urlCSS)
        }
      }, [props.specificCSS]);

    if (props){
        var idArena = props.idArena;
        var datas = props.datas;
        var datasTiming = props.datasTiming;
        var datasStats = props.datasStats;
    }
    return (
        <>
            <div className="mini2_full_mainbackground">
                
                <div className="mini2_line">
                    &nbsp;
                </div>
                {datas && datas.live ?
                <>
                <div className="mini2_full-rider">
                    {datas.live.rider}
                </div>
                <div className="mini2_full-flag">                    
                    {datas.live.nation &&
                        <img className="mini2_full-drapeau" alt="drapeau" src={"/img/flags/"+datas.live.nation+'.png'}/>
                    }
                </div> 
                <div className="mini2_full-horse">
                    {datas.live.horse}
                </div>
                <div className="mini2_full-chrono">
                    {datas.live.results.totalPen == 'RET' || datas.live.results.totalPen == 'EL' ?<></>:<>{datasTiming.countDown ? datasTiming.countDownValue : datasTiming.time}</>}
                </div>
                <div className="mini2_full-points">
                    {datas.live.results.totalPen == 'RET' || datas.live.results.totalPen == 'EL' ?<>{datas.live.results.totalPen}</>:<>{datasTiming.totalFaults}</>}
                </div>
                <div className="mini2_full-extraTxt">
                    {datas.extraTxt && 
                        <>
                            <div className="mini2_full-extraTxt1">{datas.extraTxt.line1}</div>
                            <div className="mini2_full-extraTxt2">{datas.extraTxt.line2}</div>
                        </>
                    }   
                </div>    
                {(datas.live.results.entryRank) &&
                <div className="mini2_full-rank">
                    {datas.live.results.extraPoints ?
                    <>
                        {datas.live.results.extraPoints}
                    </>:<>
                    {datas.live.results.entryRank ? <>{'Rk. '+datas.live.results.entryRank}</>:null}</>}
                </div>
                }


                
                <div className="mini2_full-ecart">
                    {datas.timeDiff && 
                        <>
                        {datas.timeDiff > 0 ? 
                        <><Badge bg="danger">{'+'+datas.timeDiff}</Badge></>
                        :
                        <><Badge bg="success">{datas.timeDiff}</Badge></>} 
                        </>
                    }   
                </div>
              
                </>
                :
                <div className="mini2_full-chrono">
                    {<>{datasTiming.countDown ? datasTiming.countDownValue : datasTiming.time}</>}
                </div>
                }

                {(datasStats && datasStats.alogo && datasStats.alogo.live_tv) &&
                <>
                    <div className="mini2-ecartlive-lines">
                        {datasStats.alogo.live_tv.ecart3 && 
                            <div className="mini2-ecartlive" style={{ backgroundImage: datasStats.alogo.live_tv.ecart3.LiveValue>0?"url(/img/mini2/scb-ecart-live-rouge.png)":"url(/img/mini2/scb-ecart-live-vert.png)"}}>
                                <div className="mini2-ecartlive-title">
                                    3
                                </div>
                                <div className="mini2-ecartlive-text">
                                    {datasStats.alogo.live_tv.ecart3.LiveValue>0&&'+'}{datasStats.alogo.live_tv.ecart3.LiveValue}
                                </div>
                            </div>  
                        } 
                        {datasStats.alogo.live_tv.ecart2 && 
                            <div className="mini2-ecartlive" style={{ backgroundImage: datasStats.alogo.live_tv.ecart2.LiveValue>0?"url(/img/mini2/scb-ecart-live-rouge.png)":"url(/img/mini2/scb-ecart-live-vert.png)"}}>
                                <div className="mini2-ecartlive-title">
                                    2
                                </div>
                                <div className="mini2-ecartlive-text">
                                {datasStats.alogo.live_tv.ecart2.LiveValue>0 &&'+'}{datasStats.alogo.live_tv.ecart2.LiveValue}
                                </div>
                            </div>  
                        }   
                        {datasStats.alogo.live_tv.ecart1 && 
                            <div className="mini2-ecartlive" style={{ backgroundImage: datasStats.alogo.live.ecart1.LiveValue>0?"url(/img/mini2/scb-ecart-live-rouge.png)":"url(/img/mini2/scb-ecart-live-vert.png)"}}>
                                <div className="mini2-ecartlive-title">
                                    1
                                </div>
                                <div className="mini2-ecartlive-text">
                                {datasStats.alogo.live_tv.ecart1.LiveValue>0 &&'+'}{datasStats.alogo.live_tv.ecart1.LiveValue}
                                </div>
                            </div>  
                        }                                               
                    </div>
                </>
                }                
            </div>
        </>
      );
  }

  export default MiniSo01;

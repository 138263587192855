import React, { useState, useEffect, useRef } from "react";
import moment from 'moment/min/moment-with-locales'
import {useInterval} from '../Functions'
import Logo from '../Logo'
import _ from 'lodash';
import './scb_mini.css'
import MiniSo01 from './SO/mini_so_01'
import MiniSoBackground from './SO/mini_so_background'
import MiniSoCountdown from './SO/mini_so_countdown'
import {dhDatas} from '../tests/dh.json';
import {statsDatas} from '../tests/stats.json';
import {timingDatas} from '../tests/timing.json';

function sceneSelect(datas,datasTiming,datasTeam,idArena,specificCSS=false,datasStats=null) {
  let queryParameters = new URLSearchParams(window.location.search)
  let scene = queryParameters.get("scene")
  if (datas || scene){
      if (!scene){
        scene = datas.scenes.mini2
        if (!scene){
          scene = datas.scenes.giant
        }        
      }
      switch (scene) {
        case 'ranking':
          return (<MiniSoBackground idArena={idArena} specificCSS={specificCSS} />)
        case 'team':
          return (null)
        case 'blank':
          return (<MiniSoBackground idArena={idArena} specificCSS={specificCSS} />)
        case 'countdown':
          return (<MiniSoCountdown datas={datas} idArena={idArena} specificCSS={specificCSS} />)  
        default:
          return (<MiniSo01 datas={datas} datasTiming={datasTiming} idArena={idArena} specificCSS={specificCSS} datasStats={datasStats}/>)
      }
  } else {    
    return (<MiniSo01 datas={datas} datasTiming={datasTiming} idArena={idArena} specificCSS={specificCSS} datasStats={datasStats}/>)
  }
}

function MainMini(props) {
    if (props){
        var idArena = props.idArena;
    }
    const[startTick,setStartTick] = useState(null)
    const[timerTiming,setTimerTiming] = useState(null)
    const[timerRunning,setTimerRunning] = useState(false)
    const[lastPing,setLastPing] = useState(null)
    const[specificCSS,setspecificCSS] = useState(false)
    const [datas,setDatas] = useState();
    const [datasTeam,setDatasTeam] = useState();
    const [datasStats,setDatasStats] = useState();
    const [datasTiming,setDatasTiming] = useState({
      running:false,
      countDown:false,
      countDownValue: null,
      faults: null,
      totalFaults: null,
      fenceFaults: null,
      timeFaults: null,
      timeAdded: null,
      phase: 1,
      time:null
    });
    var _ws = null;
    var _url = process.env.REACT_APP_URL_WS
    const [isConnected,setIsConnected] = useState(false);

    useInterval(() => {
      var actual = Date.now()
      var reco 
      reco = false
      if (lastPing && (parseFloat(actual)-parseFloat(lastPing))>10000){
        reco = true
      }
      if (isConnected == false){
        reco = true
      }
      if (reco==true){
        if (_ws){
          _ws.close()
          _ws = null
        }
        console.log('reconnect')
        connectWS(idArena)
      }
    }, 5000);

    useEffect(() => { //initialisation
        
        connectWS(idArena)
        fetch(process.env.PUBLIC_URL + '/img/events/'+idArena+'/scb_mini_2/scb_mini_specific.css',
          { method: "HEAD" }
        ).then((res) => {
          if (res.ok){
            console.log ('css exist !')
            setspecificCSS(true)
            setTestDatas();
          } else {
            console.log ('css not exist !')
            setTestDatas();
          }
        })
    },[]);

    const setTestDatas = ()=>{
      let queryParameters = new URLSearchParams(window.location.search)
      let isTest = queryParameters.get("isTest")
      if (isTest){
        setDatas((datas) => {
          return Object.assign({}, datas, dhDatas.payload.message);
        })  
        setDatasStats((datasStats) => {
          return Object.assign({}, datasStats, statsDatas.payload.message);
        })         
        output(timingDatas);
      }
    }

    const connectWS = (idArena) => {
      if (!idArena){return false;}
      const ws = new WebSocket(_url)
      _ws = ws
      ws.onopen = () => {
        console.log('Connected to the server')
        var message = {
          action: 'subscribe',
          payload: {
            topic: "dh-arena-"+idArena,
          },
        }
        message = JSON.stringify(message)
        ws.send(message)
        var message = {
            action: 'subscribe',
            payload: {
              topic: "chrono-arena-"+idArena,
            },
          }
      message = JSON.stringify(message)
      ws.send(message)
        var message = {
          action: 'subscribe',
          payload: {
            topic: "team-arena-"+idArena,
          },
        }
      message = JSON.stringify(message)
      ws.send(message)
      var message = {
        action: 'subscribe',
        payload: {
          topic: "stats-arena-"+idArena,
        },
      }
      message = JSON.stringify(message)
      ws.send(message)
        setIsConnected (true)
      }
      // listen a message from the server
      ws.onmessage = (message) => {
        const jsonMessage = JSON.parse(message.data)
        const action = jsonMessage.action
        const payload = jsonMessage.payload
        switch (action) {
          case 'me':
            break
          case 'publish':
              if(idArena){
                switch (payload.topic) {
                  case 'chrono-arena-'+idArena:
                    output(payload.message);
                    break
                  case 'dh-arena-'+idArena:
                    if (!payload.message){
                      setDatas(null)
                    }else if(payload.message.timeDiff){
                      setDatas((datas) => {
                        return {...datas, timeDiff:fixedTo(payload.message.timeDiff,2) }
                      })
                      setTimeout(()=>{
                        setDatas((datas) => {
                          return {...datas, timeDiff:null }
                        })
                      }, 8000);
                    } else {
                      setDatas((datas) => {
                        return Object.assign({}, datas, payload.message);
                      })  
                    }
                    break
                  case 'team-arena-'+idArena:
                    setDatasTeam((datasTeam) => {
                      return Object.assign({}, datasTeam, payload.message);
                    })  
                  case 'stats-arena-'+idArena:
                    if (!payload.message){                      
                      setDatasStats(null)
                    }else{
                      if (payload.message.alogo && payload.message.alogo.live_tv  && payload.message.alogo.live_tv.ecart1){
                        payload.message.alogo.live_tv.ecart1.LiveValue = fixedTo(parseFloat(payload.message.alogo.live_tv.ecart1.LiveValue),2)
                      }
                      if (payload.message.alogo && payload.message.alogo.live_tv  && payload.message.alogo.live_tv.ecart2){
                        payload.message.alogo.live_tv.ecart2.LiveValue = fixedTo(parseFloat(payload.message.alogo.live_tv.ecart2.LiveValue),2)
                      }
                      if (payload.message.alogo && payload.message.alogo.live_tv  && payload.message.alogo.live_tv.ecart3){
                        payload.message.alogo.live_tv.ecart3.LiveValue = fixedTo(parseFloat(payload.message.alogo.live_tv.ecart3.LiveValue),2)
                      }
                      setDatasStats((datasStats) => {
                        return Object.assign({}, datasStats, payload.message);
                      })  
                    }
                    break                                        
                }  
              }
            break
          case 'ping':
            setLastPing(payload.message)
            break
          default:
            break
        }
      }
      ws.onerror = (err) => {
        console.log('unable connect to the server', err)    
        setIsConnected (false)
      }
      ws.onclose = () => {
        console.log('Connection is closed') 
        setIsConnected (false)
      }
    }
    const fixedTo = (number, digits) => {
      if(_.isFinite(number)){
        var k = Math.pow(10, digits);
        var value = Math.round(number * k) / k;
        return value.toFixed(digits);
      } else {
        return null;
      }
    }

    const output = (data)=>{
      data.faults = data.faults || 0;
      if(!data.timeAdded){
        data.timeAdded = null;
      }
      if(data.countDownValue){
        data.countDownValue = parseFloat(data.countDownValue, 10);
      }
      if (data.countDown && !data.running){
        start(Date.now() - (data.countDownValue * 1000));
      } else{
        if(data.running){
          data.time = fixedTo(parseFloat(data.time, 10) || 0, 1); 
          start(Date.now() - (parseFloat(data.time, 10) * 1000));
        } else {
          if(datasTiming.countDown && !data.countDown){
            data.countDown = true;
          }
          if (data.time){
            data.time = fixedTo(parseFloat(data.time, 10) || 0, 2);
          }
          
          data.countDownValue = fixedTo(parseFloat(data.countDownValue, 10), 2);
          stop();
        }
      }
      setDatasTiming(data);
    }
    
    const start = (tick) => {
      setStartTick(tick);
      if (!timerRunning){
        setTimerRunning(true)
      }
    }

    const updateTime = () => {
      var t = (Date.now() - startTick) / 1000;
      if(datasTiming.countDown){
        setDatasTiming((datasTiming) => {
          return {...datasTiming, countDownValue: fixedTo(t, 1)}
        })
        if (datas && datas.timeDiff){
          setDatas((datas) => {
            return {...datas, timeDiff: null}
          })  
        }
      }else{
        if(datasTiming.running){
          setDatasTiming((datasTiming) => {
            return {...datasTiming, time: fixedTo(t, 1)}
          })
        }
      }
    }

    useEffect(() => {
      let tmr; 
      if (timerRunning){
        tmr = setInterval(updateTime, 100);
        setTimerTiming(tmr);  
      }
      return ()=>clearInterval(tmr)
    },[timerRunning,datasTiming]);
  
    const stop = () => {
      // clearInterval(timerTiming);
      // timerTiming = null;
      setTimerRunning(false)
      clearInterval(timerTiming)
      setTimerTiming(null)
    }

    return (
      <>
        {sceneSelect(datas,datasTiming,datasTeam,idArena,specificCSS,datasStats)}
      </>
      );
    
  }

  export default MainMini;

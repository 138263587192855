import React, { useState, useEffect, useRef } from "react";
import Badge from 'react-bootstrap/Badge';
import {applyFontStretch} from '../../functions/strech.js'

function GiantSo01(props) {

    const [ stylePath, setStylePath ] = useState(null);
    useEffect(() => {    
        if (stylePath){
            var head = document.head;
            var link = document.createElement("link");
        
            link.type = "text/css";
            link.rel = "stylesheet";
            link.href = stylePath;
        
            head.appendChild(link);    
            console.log(link)
            return () => { head.removeChild(link); }
        }
        
      }, [stylePath]);
    useEffect(() => {
    console.log('specific css '+props.specificCSS)
    if (props.specificCSS){
        var urlCSS = '/img/events/'+idArena+'/giant/giant_specific.css'
        setStylePath(urlCSS)
    }
    }, [props.specificCSS]);

    if (props.specificGFX){
        var urlGFX = '/img/events/'+idArena+'/giant/'
    } else {
        var urlGFX = '/img/giant/'
    }     



    if (props){
        var idArena = props.idArena;
        var datas = props.datas;
        var datasTiming = props.datasTiming;
        var datasStats = props.datasStats;
        var datasTeamLive = props.datasTeamLive;
        if (props.specificGFX){
            var urlGFX = '/img/events/'+idArena+'/giant/'
        } else {
            var urlGFX = '/img/giant/'
        }          
    }
    if (props.specificFlag){
        var urlFlag = '/img/events/'+idArena+'/giant/flags/'
    } else {
        var urlFlag = "/img/flags/"
    }    

    useEffect(() => {
        applyFontStretch(".giant-rider")
        applyFontStretch(".giant-horse")
    },[datas])

    //datasTeamLive={"active":"GBR","teams":[{"points":"12","nation":"FRA","rank":4},{"points":"4","nation":"GBR","rank":4},{"points":"4","nation":"FRA","rank":4},{"points":"4","nation":"FRA","rank":4},{"points":"4","nation":"FRA","rank":4},{"points":"4","nation":"FRA","rank":4},{"points":"4","nation":"FRA","rank":4},{"points":"4","nation":"FRA","rank":4},{"points":"4","nation":"FRA","rank":4}]}
    return (
        <>
            <div style={{ backgroundColor: "transparent",height:'100%',width:'100%'}}>
                {datas.live &&
                <>
                <div className="giant-presentation" style={{ backgroundImage: "url("+urlGFX+"scb-presentation-background.png)"}}>
                    <div className="giant-rider">
                        <p>{datas.live.rider}</p>
                    </div>
                    <div className="giant-horse">
                        <p>{datas.competition.isInter ? datas.live.order : datas.live.hnb} - {datas.live.horse}</p>
                    </div>
                    <div className="giant-flag">
                        {datas.live.nation &&
                            <img alt="drapeau" className="giant-drapeau" src={urlFlag+datas.live.nation+'.png'}/>
                        }
                    </div>
                    <div className="giant-flag-text">
                        {datas.live.nation &&
                            datas.live.nation
                        }
                    </div>                    
                </div>
                {datas.live.results.totalPen == 'RET' || datas.live.results.totalPen == 'EL' ?
                    <div className="giant-rank" style={{ backgroundImage: "url("+urlGFX+"scb-champ-background.png)"}}>
                        <div className="giant-rank-text">
                            {datas.live.results.totalPen}
                        </div>
                    </div>
                :
                <>
                <div className="giant-chrono" style={{ backgroundImage: "url("+urlGFX+"scb-time-background.png)"}}>
                    <div className="giant-chrono-text">
                        {datasTiming.countDown ? datasTiming.countDownValue : datasTiming.time}
                    </div>
                </div>
                {datasTiming.countDownValue || datasTiming.time=="-45.00"? null : 
                    <div className="giant-points" style={{ backgroundImage: "url("+urlGFX+"scb-pen-background.png)"}}>
                        <div className="giant-points-text">
                            {datasTiming.totalFaults}
                        </div>
                    </div>
                }
                {datas.live.results.entryRank && <>
                    <div className="giant-rank" style={{ backgroundImage: "url("+urlGFX+"scb-champ-background.png)"}}>
                        <div className="giant-rank-text">
                        {datas.live.results.extraPoints ?
                            <>
                                {datas.live.results.extraPoints}
                            </>:<>
                            {datas.live.results.entryRank ? <>{'Rank: '+datas.live.results.entryRank+'.'}</>:null}
                            </>
                        }
                        </div>
                    </div>
                </>}
                
                {datas.timeDiff && 
                    <>
                    {datas.timeDiff > 0 ? 
                    <>
                        <div className="giant-ecart" style={{ backgroundImage: "url("+urlGFX+"scb-ecart-rouge.png)"}}>
                            <div className="giant-ecart-text">
                                {'+'+datas.timeDiff}
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="giant-ecart" style={{ backgroundImage: "url("+urlGFX+"scb-ecart-vert.png)"}}>
                            <div className="giant-ecart-text">
                                {datas.timeDiff}
                            </div>
                        </div>                    
                    </>} 
                    </>
                }

                {datas.ecurieGN && 
                <>
                    <div className="giant-gn" style={{ backgroundImage: "url("+urlGFX+"scb-gn.png)"}}>
                        <div className="giant-gn-text">
                            {datas.ecurieGN.libelle}
                        </div>
                        <img className="giant-gn-logo" alt="ecurieGN" width="100%" src={"/img/ecuries/so/"+datas.ecurieGN.num+'.png'}/>
                    </div>                    
                </>
                }
                </>                
                }
                </>
                }
                {datasTeamLive && 
                    <div className="giant-team-live">
                        {datasTeamLive.teams.map((d,index) => {
                            var classFond
                            if(datasTeamLive.active==d.nation){
                                classFond = "giant-team-live-line-selected"
                            }else if (index%2==0){
                                classFond = "giant-team-live-line-even"
                            } else {
                                classFond = "giant-team-live-line-odd"
                            }
                            return(
                            <div className={"giant-team-live-line "+classFond} >
                                <div className="giant-team-live-rank">
                                    {d.rank}.
                                </div>
                                <div className="giant-team-live-nation">
                                    <img className="giant-team-live-flag" alt="drapeau" src={urlFlag+d.nation+".png"}/>
                                </div>
                                <div className="giant-team-live-nation-text">
                                    {d.nation}
                                </div>
                                <div className="giant-team-live-points">
                                    {d.points}
                                </div>
                            </div>)
                        })}            
                    </div>
                }
                {(datasStats && datasStats.alogo && datasStats.alogo.live_tv) &&
                <>
                    <div className="giant-ecartlive-lines">
                        {datasStats.alogo.live_tv.ecart3 && 
                            <div className="giant-ecartlive" style={{ backgroundImage: datasStats.alogo.live_tv.ecart3.LiveValue>0?"url(" + urlGFX+"scb-ecart-live-rouge.png)":"url(" + urlGFX+"scb-ecart-live-vert.png)"}}>
                                <div className="giant-ecartlive-title">
                                    3
                                </div>
                                <div className="giant-ecartlive-text">
                                    {datasStats.alogo.live_tv.ecart3.LiveValue>0&&'+'}{datasStats.alogo.live_tv.ecart3.LiveValue}
                                </div>
                            </div>  
                        } 
                        {datasStats.alogo.live_tv.ecart2 && 
                            <div className="giant-ecartlive" style={{ backgroundImage: datasStats.alogo.live_tv.ecart2.LiveValue>0?"url(" + urlGFX+"scb-ecart-live-rouge.png)":"url(" + urlGFX+"scb-ecart-live-vert.png)"}}>
                                <div className="giant-ecartlive-title">
                                    2
                                </div>
                                <div className="giant-ecartlive-text">
                                {datasStats.alogo.live_tv.ecart2.LiveValue>0 &&'+'}{datasStats.alogo.live_tv.ecart2.LiveValue}
                                </div>
                            </div>  
                        }   
                        {datasStats.alogo.live_tv.ecart1 && 
                            <div className="giant-ecartlive" style={{ backgroundImage: datasStats.alogo.live.ecart1.LiveValue>0?"url(" + urlGFX+"scb-ecart-live-rouge.png)":"url(" + urlGFX+"scb-ecart-live-vert.png)"}}>
                                <div className="giant-ecartlive-title">
                                    1
                                </div>
                                <div className="giant-ecartlive-text">
                                {datasStats.alogo.live_tv.ecart1.LiveValue>0 &&'+'}{datasStats.alogo.live_tv.ecart1.LiveValue}
                                </div>
                            </div>  
                        }                                               
                    </div>
                </>
                }
            </div>
        </>
      );
  }

  export default GiantSo01;

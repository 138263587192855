import React, { useState, useEffect, useRef } from "react";
import Badge from 'react-bootstrap/Badge';
import {applyFontStretch} from '../../functions/strech.js'
import moment from 'moment/min/moment-with-locales'
import {useInterval} from '../../Functions'

function GiantCountDown(props) {
    if (props){
        var idArena = props.idArena;
        var datas = props.datas;
        if (props.specificGFX){
            var urlGFX = '/img/events/'+idArena+'/giant/'
        } else {
            var urlGFX = '/img/giant/'
        }     
    }

    useEffect(() => {

    },[datas])
    const[time,setTime] = useState("");
    useInterval(() => {            
        if(datas.countDown && datas.countDown.end){
            var start = moment().format('HH:mm:ss');    
            var end = datas.countDown.end;
            var diff = moment.duration(moment(end, "HH:mm:ss a").diff(moment(start, "HH:mm:ss a")));
            var time = ""
            if (diff.asSeconds()>=0){
                if (diff.hours()>0){
                    time = time+diff.hours().toString().padStart(2, '0')+":"
                }
                if (diff.minutes()>0){
                    time = time+diff.minutes().toString().padStart(2, '0')+":"
                }
                time = time + diff.seconds().toString().padStart(2, '0')    
            } else {
                time="00"
            }

            setTime(time);
        } else {            
            setTime(moment().format('HH:mm:ss'));
        }        
      }, 500);    


    return (
        <>
            <div style={{ backgroundColor: "transparent",height:'100%',width:'100%'}}>
                <div className="giant-countdown">
                    {time}
                </div>
            </div>
        </>
      );
  }

  export default GiantCountDown;

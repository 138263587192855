import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

class AlogoStats extends React.Component {
    render(){
        return(
            <>
            <br/>
            <Container fluid className="speak_table-stats">
                <Row className="">
                    <Col xs={12} className="alogoStatsTitle">  
                        Most Knocked Obstacles by ALOGO
                    </Col>
                    <Col xs={12} style={{padding:0}}>   
                        <table className="alogoStatsTable" >
                            <tbody>
                            <tr>
                                <td className="alogoStatsCell">Obst.</td>
                                {this.props.alogoStats.obstacles.map((d,index)=>{
                                    return(<td key={index} className="alogoStatsCell">{d.ObstName}</td>)
                                })}                                
                            </tr>    
                            <tr>
                                <td className="alogoStatsCell">Knocked</td>
                                {this.props.alogoStats.obstacles.map((d,index)=>{
                                    return(<td key={index} className="alogoStatsCell">{d.ObstKnockedCount+' ('+d.ObstKnockedPercent+'%)'}</td>)
                                })}                                
                            </tr>
                            </tbody>
                        </table>    
                    </Col>
                </Row>
            </Container>
            </>
        )
    }
}
export default AlogoStats;

import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class TableLine extends React.Component {

    render(){
        var classFond
        if (this.props.index%2==0){
            classFond = "speak_fond_impair"
        } else {
            classFond = "speak_fond_pair"
        }
    return(
        <>
        {this.props.entry?
        <Row className={"speak_line-res "+classFond}>
            <Col xs={1} className="speak_line-res-cont-rank">   
                <span className="speak_line-res-rank">{this.props.entry.entryRank>0?this.props.entry.entryRank+'.':null}</span> 
            </Col>
            <Col xs={8} className="speak_line-res-cav-horse">
                {this.props.entry.rider}<br/><span className="speak_line-res-horse">{this.props.entry.horse}</span>
            </Col>
            <Col xs={3} className="speak_line-res-points speak_alignCenter">
                <br/>
                {this.props.entry.nation && <img alt="drapeau" width="70px" src={"/img/flags/"+this.props.entry.nation+'.png'}/>}
            </Col>
            <Col xs={12} className="speak_line-res-cont-rank speak_alignCenter">   
                {this.props.entry.res}
            </Col>
        </Row>
        :null}
        </>
)
}
}
export default TableLine;

import React, { useState, useEffect, useRef } from "react";
import moment from 'moment/min/moment-with-locales'
import {useInterval} from '../Functions'
import Logo from '../Logo'
import _ from 'lodash';
import './Giant.css'
import GiantSo01 from './SO/giant_so_01'
import GiantRanking from './SO/giant_ranking'
import GiantCountdown from './SO/giant_countdown'
import {dhDatas} from '../tests/dh.json';
import {timingDatas} from '../tests/timing.json';
import {statsDatas} from '../tests/stats.json';


function sceneSelect(datas,datasTiming,idArena,specificGFX,datasStats=null,datasTeamLive=null,datasDisplayList=null,specificCSS=false,specificFlag=false) {
  if (datas){
    if (datas.scenes){
      let queryParameters = new URLSearchParams(window.location.search)
      let scene = queryParameters.get("scene")
      if (!scene){scene = datas.scenes.giant}

      switch (scene) {
        case 'ranking':
          return (<GiantRanking datas={datas} datasTiming={datasTiming} idArena={idArena} specificGFX={specificGFX} datasDisplayList={datasDisplayList} specificCSS={specificCSS} specificFlag={specificFlag}/>)
        case 'countdown':
          return (<GiantCountdown datas={datas} idArena={idArena} specificGFX={specificGFX} />)          
        case 'blank':
          return (null)
        default:
          return (<GiantSo01 datas={datas} datasTiming={datasTiming} idArena={idArena} specificGFX={specificGFX} datasStats={datasStats} datasTeamLive={datasTeamLive} specificCSS={specificCSS} specificFlag={specificFlag}/>)
      }
    } else {
      return (<GiantSo01 datas={datas} datasTiming={datasTiming} idArena={idArena} specificGFX={specificGFX} specificCSS={specificCSS} specificFlag={specificFlag}/>)
    }
  } else {
    return (<Logo no_data={true} type={"Giant channel"}  />)
  }
}

function MainGiant(props) {
    if (props){
        var idArena = props.idArena;
    }
    const[startTick,setStartTick] = useState(null)
    const[specificGFX,setspecificGFX] = useState(false)
    const[specificCSS,setspecificCSS] = useState(false)
    const[specificFlag,setspecificFlag] = useState(false)
    const[timerTiming,setTimerTiming] = useState(null)
    const[timerRunning,setTimerRunning] = useState(false)
    const[lastPing,setLastPing] = useState(null)
    const[time,setTime] = useState("99:99:99");
    const [datas,setDatas] = useState();
    const [datasTeamLive,setDatasTeamLive] = useState();
    const [datasStats,setDatasStats] = useState();
    const [datasDisplayList,setDatasDisplayList] = useState();
    const [datasTiming,setDatasTiming] = useState({
      running:false,
      countDown:false,
      countDownValue: null,
      faults: null,
      totalFaults: null,
      fenceFaults: null,
      timeFaults: null,
      timeAdded: null,
      phase: 1,
      time:null
    });
    var _ws = null;
    var _url = process.env.REACT_APP_URL_WS
    const [isConnected,setIsConnected] = useState(false);

    useInterval(() => {
      setTime(moment().format('HH:mm:ss'));
    }, 100);

    useInterval(() => {
      var actual = Date.now()
      var reco 
      reco = false
      if (lastPing && (parseFloat(actual)-parseFloat(lastPing))>10000){
        reco = true
      }
      if (isConnected == false){
        reco = true
      }
      if (reco==true){
        if (_ws){
          _ws.close()
          _ws = null
        }
        console.log('reconnect')
        connectWS(idArena)
      }
    }, 5000);

    useEffect(() => { //initialisation
        connectWS(idArena)
        fetch(process.env.PUBLIC_URL + '/img/events/'+idArena+'/giant/scb-presentation-background.png',
          { method: "HEAD" }
        ).then((res) => {
          console.log(res)
          if (res.ok){
            console.log ('exist !')
            setspecificGFX(true)
            setTestDatas();
          } else {
            console.log ('not exist !')
            setTestDatas();
          }
        })
        fetch(process.env.PUBLIC_URL + '/img/events/'+idArena+'/giant/giant_specific.css',
          { method: "HEAD" }
        ).then((res) => {
          if (res.ok){
            console.log ('css exist !')
            setspecificCSS(true)
            setTestDatas();
          } else {
            console.log ('css not exist !')
            setTestDatas();
          }
        })
        fetch(process.env.PUBLIC_URL + '/img/events/'+idArena+'/giant/flags/FRA.png',
          { method: "HEAD" }
        ).then((res) => {
          if (res.ok){
            console.log ('flag exist !')
            setspecificFlag(true)
            setTestDatas();
          } else {
            console.log ('flag not exist !')
            setTestDatas();
          }
        })        
    },[]);

    const setTestDatas = ()=>{
      let queryParameters = new URLSearchParams(window.location.search)
      let isTest = queryParameters.get("isTest")
      if (isTest){
        setDatas((datas) => {
          return Object.assign({}, datas, dhDatas.payload.message);
        }) 
        setDatasStats((datasStats) => {
          return Object.assign({}, datasStats, statsDatas.payload.message);
        }) 
        output(timingDatas);
      }
    }


    const connectWS = (idArena) => {
      if (!idArena){return false;}
      const ws = new WebSocket(_url)
      _ws = ws
      ws.onopen = () => {
        console.log('Connected to the server')
        var message = {
          action: 'subscribe',
          payload: {
            topic: "dh-arena-"+idArena,
          },
        }
        message = JSON.stringify(message)
        ws.send(message)
        var message = {
            action: 'subscribe',
            payload: {
              topic: "chrono-arena-"+idArena,
            },
          }
        message = JSON.stringify(message)
        ws.send(message)
        var message = {
          action: 'subscribe',
          payload: {
            topic: "stats-arena-"+idArena,
          },
        }
        message = JSON.stringify(message)
        ws.send(message)
        var message = {
          action: 'subscribe',
          payload: {
            topic: "team-live-arena-"+idArena,
          },
        }
        message = JSON.stringify(message)
        ws.send(message)
        var message = {
          action: 'subscribe',
          payload: {
            topic: "display-list-giant-arena-"+idArena,
          },
        }
        message = JSON.stringify(message)
        ws.send(message)        
        setIsConnected (true)
      }
      // listen a message from the server
      ws.onmessage = (message) => {
        const jsonMessage = JSON.parse(message.data)
        const action = jsonMessage.action
        const payload = jsonMessage.payload
        switch (action) {
          case 'me':
            break
          case 'publish':
              if(idArena){
                switch (payload.topic) {
                  case 'chrono-arena-'+idArena:
                    output(payload.message);
                    break
                  case ('team-live-arena-'+idArena):
                    setDatasTeamLive(payload.message)
                    break
                  case ('display-list-giant-arena-'+idArena):
                    setDatasDisplayList(payload.message)
                    break                    
                  case 'stats-arena-'+idArena:
                    if (!payload.message){                      
                      setDatasStats(null)
                    }else{
                      if (payload.message.alogo && payload.message.alogo.live_tv  && payload.message.alogo.live_tv.ecart1){
                        payload.message.alogo.live_tv.ecart1.LiveValue = fixedTo(parseFloat(payload.message.alogo.live_tv.ecart1.LiveValue),2)
                      }
                      if (payload.message.alogo && payload.message.alogo.live_tv  && payload.message.alogo.live_tv.ecart2){
                        payload.message.alogo.live_tv.ecart2.LiveValue = fixedTo(parseFloat(payload.message.alogo.live_tv.ecart2.LiveValue),2)
                      }
                      if (payload.message.alogo && payload.message.alogo.live_tv  && payload.message.alogo.live_tv.ecart3){
                        payload.message.alogo.live_tv.ecart3.LiveValue = fixedTo(parseFloat(payload.message.alogo.live_tv.ecart3.LiveValue),2)
                      }
                      setDatasStats((datasStats) => {
                        return Object.assign({}, datasStats, payload.message);
                      })  
                    }
                    break
    
                  case 'dh-arena-'+idArena:
                    if (!payload.message){
                      setDatas(null)
                    }else if(payload.message.timeDiff){
                      setDatas((datas) => {
                        return {...datas, timeDiff:fixedTo(payload.message.timeDiff,2) }
                      })
                      setTimeout(()=>{
                        setDatas((datas) => {
                          return {...datas, timeDiff:null }
                        })
                      }, 8000);
                    } else if (payload.message.ecurieGN){
                      setDatas((datas) => {
                        return {...datas, ecurieGN:payload.message.ecurieGN}
                      })
                      setTimeout(()=>{
                        setDatas((datas) => {
                          return {...datas, ecurieGN:null }
                        })
                      }, 45000);
                    } else {
                      setDatas((datas) => {
                        return Object.assign({}, datas, payload.message);
                      })  
                    }
                    break
                }  
              }
            break
          case 'ping':
            setLastPing(payload.message)
            break
          default:
            break
        }
      }
      ws.onerror = (err) => {
        console.log('unable connect to the server', err)    
        setIsConnected (false)
      }
      ws.onclose = () => {
        console.log('Connection is closed') 
        setIsConnected (false)
      }
    }
    const fixedTo = (number, digits) => {
      if(_.isFinite(number)){
        var k = Math.pow(10, digits);
        var value = Math.round(number * k) / k;
        return value.toFixed(digits);
      } else {
        return null;
      }
    }

    const output = (data)=>{
      //console.log(data);

      // If eliminated hide the clock
      //data.isEliminated = !!ShowJumpingEvent[data.fenceFaults];
  
      // Normalize
      data.faults = data.faults || 0;
      if(!data.timeAdded){
        data.timeAdded = null;
      }
      if(data.countDownValue){
        data.countDownValue = parseFloat(data.countDownValue, 10);
      }
      if (data.countDown && !data.running){
        start(Date.now() - (data.countDownValue * 1000));
      } else{
        if(data.running){
          data.time = fixedTo(parseFloat(data.time, 10) || 0, 1); 
          start(Date.now() - (parseFloat(data.time, 10) * 1000));
        } else {
          if(datasTiming.countDown && !data.countDown){
            data.countDown = true;
          }
          data.time = fixedTo(parseFloat(data.time, 10) || 0, 2);
          data.countDownValue = fixedTo(parseFloat(data.countDownValue, 10), 2);
          if (datas && datas.timeDiff && data.countDownValue == "-45.00"){
            setDatas((datas) => {
              return {...datas, timeDiff: null}
            })  
          }
          stop();
        }
      }
      setDatasTiming(data);
    }
    
    const start = (tick) => {
      setStartTick(tick);
      if (!timerRunning){
        setTimerRunning(true)
      }
    }

    const updateTime = () => {
      var t = (Date.now() - startTick) / 1000;
      if(datasTiming.countDown){
        setDatasTiming((datasTiming) => {
          return {...datasTiming, countDownValue: fixedTo(t, 1)}
        })
        if (datas && datas.timeDiff){
          setDatas((datas) => {
            return {...datas, timeDiff: null}
          })  
        }
      }else{
        if(datasTiming.running){
          setDatasTiming((datasTiming) => {
            return {...datasTiming, time: fixedTo(t, 1)}
          })
        }
      }
    }

    useEffect(() => {
      let tmr; 
      if (timerRunning){
        tmr = setInterval(updateTime, 100);
        setTimerTiming(tmr);  
      }
      return ()=>clearInterval(tmr)
    },[timerRunning,datasTiming]);
  
    const stop = () => {
      setTimerRunning(false)
      clearInterval(timerTiming)
      setTimerTiming(null)
    }

    return (
      <>
        {sceneSelect(datas,datasTiming,idArena,specificGFX,datasStats,datasTeamLive,datasDisplayList,specificCSS,specificFlag)}
      </>
      );
    
  }

  export default MainGiant;

import React, { useState, useEffect, useRef } from "react";
import Badge from 'react-bootstrap/Badge';

function TvRanking(props) {
    if (props){
        var idArena = props.idArena;
        var datas = props.datas;
        var datasTiming = props.datasTiming;
    }

    if (props.specificGFX){
        var urlGFX = '/img/events/'+idArena+'/tv/'
    } else {
        var urlGFX = '/img/tv/'
    }     

    return (
        <>
            <div style={{ backgroundColor: "transparent",height:'100%',width:'100%',backgroundImage: "url("+urlGFX+"/tv-ranking-title.png)"}}>
                {datas.ranking &&
                <>
                <div className="tv_ranking-title">
                    <div className="tv_ranking-title-text">
                        {datas.competition.nameTV}
                    </div>
                </div>
                <div className="tv_ranking-title2">
                    <div className="tv_ranking-title-text">
                        Results
                    </div>
                </div>                
                <div className="tv_ranking-lines">
                    {datas.ranking.map((d,index)=>{
                        if (index < 6){
                            return(
                                <div key={index} className="tv_ranking-line" style={{ backgroundRepeat:'no-repeat',height:'100%',width:'100%',backgroundImage: "url("+urlGFX+"/tv-ranking-line.png)",backgroundPositionY:-350}}>
                                    <div className="tv_ranking-line-rank">
                                        {d.results.entryRank?d.results.entryRank+'.':null}
                                    </div>
                                    <div className="tv_ranking-line-rider">
                                        {d.rider}
                                    </div>
                                    <div className="tv_ranking-line-horse">
                                        {d.horse}
                                    </div>
                                    <div className="tv_ranking-flag">
                                        {d.nation &&
                                            <img alt="drapeau" width="35px" src={"/img/tv/flags/"+d.nation+'.png'}/>
                                        }
                                    </div>
                                    <div className="tv_ranking-line-nation">
                                        {d.nation}
                                    </div>
                                    <div className="tv_ranking-line-pen">
                                        {d.results.totalPen}
                                    </div>                                                            
                                    <div className="tv_ranking-line-time">
                                        {d.results.totalTime && <>{d.results.totalTime}</>}
                                    </div>                                                            
                                </div>        
                            )        
                        } else {
                            return (null)
                        }
                    })}
                </div> 
                </>   
                }    
            </div>
        </>
      );
  }

  export default TvRanking;

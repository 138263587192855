import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

class MiniTeam extends React.Component {

    render(){
        return(
            <>
        <Container fluid className="speak_mini_teams">
                        <Row>
                        <Col xs={12} className="speak_stats-title">Teams
                        </Col>
                        </Row>
                        <Row>
                        {this.props.teams.map((d,index)=>{
                            return(
                                <>
                                    <Col xs={2} className="speak_team_detail">   
                                        {d.entryRank}. <img alt="drapeau" width="70px" src={"/img/flags/"+d.nation+".png"}/>&nbsp;{d.total}{d.time>0 && <>{' / '+d.time}</>}
                                    </Col>
                                </>
                            )})}
                        </Row>
                    </Container>
            </>
        )
    }
}
export default MiniTeam;

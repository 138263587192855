import React, { useState, useEffect } from "react";
import {useInterval} from '../../Functions'
import moment from 'moment/min/moment-with-locales'

function MiniSoCountdown(props) {
    const [ stylePath, setStylePath ] = useState(null);
    useEffect(() => {    
        if (stylePath){
            var head = document.head;
            var link = document.createElement("link");
        
            link.type = "text/css";
            link.rel = "stylesheet";
            link.href = stylePath;
        
            head.appendChild(link);    
            console.log(link)
            return () => { head.removeChild(link); }
        }
        
      }, [stylePath]);
      useEffect(() => {
        console.log('specific css '+props.specificCSS)
        if (props.specificCSS){
            var urlCSS = '/img/events/'+idArena+'/scb_mini_2/scb_mini_specific.css'
            setStylePath(urlCSS)
        }
      }, [props.specificCSS]);

    if (props){
        var idArena = props.idArena;
        var datas = props.datas;
        var datasTiming = props.datasTiming;
    }

    const[time,setTime] = useState("");

    useInterval(() => {            
        if(datas.countDown && datas.countDown.end){
            var start = moment().format('HH:mm:ss');    
            var end = datas.countDown.end;
            var diff = moment.duration(moment(end, "HH:mm:ss a").diff(moment(start, "HH:mm:ss a")));
            var time = ""
            if (diff.asSeconds()>=0){
                if (diff.hours()>0){
                    time = time+diff.hours().toString().padStart(2, '0')+":"
                }
                if (diff.minutes()>0){
                    time = time+diff.minutes().toString().padStart(2, '0')+":"
                }
                time = time + diff.seconds().toString().padStart(2, '0')    
            } else {
                time="00"
            }

            setTime(time);
        } else {            
            setTime(moment().format('HH:mm:ss'));
        }        
      }, 500);    

    return (
        <>
            <div className="mini2_full_mainbackground">
                <div className="mini2_countdown">
                    {time}
                </div>
            </div>
        </>
      );
  }

  export default MiniSoCountdown;

import React, { useState, useEffect, useRef } from "react";
import Badge from 'react-bootstrap/Badge';

function TvNextComp(props) {
    if (props){
        var idArena = props.idArena;
        var datas = props.datas;
        var datasTiming = props.datasTiming;
    }

    if (props.specificGFX){
        var urlGFX = '/img/events/'+idArena+'/tv/'
    } else {
        var urlGFX = '/img/tv/'
    }     

    return (
        <>
            <div style={{ backgroundColor: "transparent",height:'100%',width:'100%',backgroundImage: "url("+urlGFX+"/tv-next-comp.png)"}}>
                {datas.competition &&
                <>
                <div className="tv_next-title">
                    <div className="tv_next-title-text">
                        {datas.competition.nameTV}
                    </div>
                </div>
                <div className="tv_next-title2">
                    <div className="tv_next-title-text">
                        Starting at {datas.competition.start}
                    </div>
                </div>                
                </>   
                }    
            </div>
        </>
      );
  }

  export default TvNextComp;

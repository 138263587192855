import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class TableLineTeam extends React.Component {

    render(){
        var classFond
        if (this.props.index%2==0){
            classFond = "speak_fond_impair"
        } else {
            classFond = "speak_fond_pair"
        }
    return(
        <>
        {this.props.entry?
        <Row className={"speak_line-res "+classFond}>
            <Col xs={1} className="speak_line-res-cont-rank">   
                <span className="speak_line-res-rank">{this.props.entry.entryRank>0?this.props.entry.entryRank+'.':null}</span> 
            </Col>
            <Col xs={2} className="speak_line-res-points speak_alignCenter">
                {this.props.entry.nation && <img alt="drapeau" width="70px" src={"/img/flags/"+this.props.entry.nation+'.png'}/>}
            </Col>

            <Col xs={5} className="speak_line-res-cav-horse">
                {this.props.entry.name} 
            </Col>
            <Col xs={4} className="speak_line-res-points speak_alignCenter">
                {this.props.entry.etat?this.props.entry.etat:
                    <>
                    {this.props.entry.total}{this.props.entry.time>0 && <>{' / '+this.props.entry.time}</>}
                    </>
                }
            </Col>
            <hr style={{marginBottom:0}}></hr>
            {this.props.entry.members.map((d,index)=>{
                return(
                <>
                {/* <Col xs={0} className="">
                    
                </Col> */}
                <Col xs={6} className="speakTeamMember" style={{paddingLeft:30}}>
                    {d.hnb} - {d.rider}
                </Col>
                <Col xs={2} className="speakTeamMember alignCenter">
                    <>{d.discard1>0 && '('}{d.etat1?d.etat1:d.pen1}{d.discard1>0 && ')'}</>
                    {/* {d.etat1?'('+d.etat1+')':<>{d.discard1>0 && '('}{d.pen1}{d.discard1>0 && ')'}</>} */}
                </Col>
                <Col xs={2} className="speakTeamMember alignCenter">
                    <>{d.discard2>0 && '('}{d.etat2?d.etat2:d.pen2}{d.discard2>0 && ')'}</>
                    {/* {d.etat2?'('+d.etat2+')':<>{d.discard2>0 && '('}{d.pen2}{d.discard2>0 && ')'}</>} */}
                </Col>
                <Col xs={2} className="speakTeamMember alignCenter">
                    <>{d.discard3>0 && '('}{d.etat3?d.etat3:d.pen3}{d.discard3>0 && ')'}</>
                    {/* {d.etat3?'('+d.etat3+')':<>{d.discard3>0 && '('}{d.pen3}{d.discard3>0 && ')'}</>} */}
                </Col>
                </>)
            })}

        </Row>
        :null}
        </>
)
}
}
export default TableLineTeam;

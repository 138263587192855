import React, { useState, useEffect } from "react";
import {useInterval} from '../../Functions'
import moment from 'moment/min/moment-with-locales'

function MiniSoBackground(props) {
    const [ stylePath, setStylePath ] = useState(null);
    useEffect(() => {    
        if (stylePath){
            var head = document.head;
            var link = document.createElement("link");
        
            link.type = "text/css";
            link.rel = "stylesheet";
            link.href = stylePath;
        
            head.appendChild(link);    
            console.log(link)
            return () => { head.removeChild(link); }
        }
        
      }, [stylePath]);
      useEffect(() => {
        console.log('specific css '+props.specificCSS)
        if (props.specificCSS){
            var urlCSS = '/img/events/'+idArena+'/scb_mini_2/scb_mini_specific.css'
            setStylePath(urlCSS)
        }
      }, [props.specificCSS]);

    if (props){
        var idArena = props.idArena;
        var datas = props.datas;
        var datasTiming = props.datasTiming;
    }

    const[time,setTime] = useState("");

    useInterval(() => {     
        setTime(moment().format('HH:mm:ss'));
      }, 500);
    // var urlBackground = "url(/img/mini/fond_.png)"

    return (
        <>
            <div className="mini2_full_mainbackground">
                <div className="mini2_time">
                    {time}
                </div>
            </div>
        </>
      );
  }

  export default MiniSoBackground;

import React, { useState, useEffect } from "react";
import MainJudges from './judges/main'
import MainGiantFull from './giant_full/main'
import MainGiantShf from './giant_shf/main'
import MainGiantFullRanking from './giant_full_ranking/main'
import MainGiant from './giant/main'
import MainSpeaker from './speaker/main'
import MainMini from './scb_mini/main'
import MainMini2 from './scb_mini_2/main'
import Tv from './tv/main'
import { useParams } from 'react-router-dom';


function Table() {
    const[wWidth,setWWidth] = useState(0);
    const {idArena,tpl} = useParams();

    useEffect(() => { //initialisation
        updateWindowDimensions();
        window.addEventListener('resize', updateWindowDimensions);
        
        return function cleanup() {
            console.log('close')
            window.removeEventListener('resize', updateWindowDimensions);    
          };        
    },[]);


    const updateWindowDimensions=()=>{
        setWWidth(window.innerWidth)
        console.log('resize')
      }
    var scale = wWidth/1920;
    var styles = { 
        transform:'scale('+scale+')',width:'1920px',height:'1080px',transformOrigin: 'top left'
    };

    return (
          <>
          <div style={styles}>
            {tpl=="judges" && <MainJudges idArena={idArena}/>}
            {tpl=="giant-full" && <MainGiantFull idArena={idArena}/>}
            {tpl=="giant-shf" && <MainGiantShf idArena={idArena}/>}
            {tpl=="giant-full-ranking" && <MainGiantFullRanking idArena={idArena}/>}
            {tpl=="giant" && <MainGiant idArena={idArena}/>}
            {tpl=="speaker" && <MainSpeaker idArena={idArena}/>}
            {tpl=="mini" && <MainMini idArena={idArena}/>}
            {tpl=="mini-2" && <MainMini2 idArena={idArena}/>}
            {tpl=="tv" && <Tv idArena={idArena}/>}
          </div>
          </>
      );
    
  }

  export default Table;

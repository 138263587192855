import React, { useState, useEffect, useRef } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import moment from 'moment/min/moment-with-locales'
import TableLine from "./TableLine";
import TableLineTeam from "./TableLineTeam";
import TableLinePuissance from "./TableLinePuissance";
import TableLineChampionnat from "./TableLinePuissance";
import MiniTeams from "./MiniTeams";
import AlogoStats from "./alogoStats";
import AlogoLive from "./alogoLive";
import {useInterval} from '../../Functions'
import {applyFontStretch} from '../../functions/strech.js'

function SpeakerSo(props) {
    if (props){
        var idArena = props.idArena;
        var datas = props.datas;
        var datasTiming = props.datasTiming;
        var datasStats = props.datasStats
        var datasNext = props.datasNext
    }
    
    const[time,setTime] = useState("99:99:99");
    const[countDown,setCountDown] = useState(null);

    useInterval(() => {
        setTime(moment().format('HH:mm:ss'));
      }, 500);

    var col1=[]
    var col2=[]
    if (datas.ranking || datasNext){
        if (datas.competition.type_ranking=="team"){
            for (let i = 0 ; i < 5; i++) {
                if (datas.ranking[i]){
                    col1.push(datas.ranking[i])
                }
              }
              for (let i = 5 ; i < 10; i++) {
                  if (datas.ranking[i]){
                      col2.push(datas.ranking[i])
                  }
              }
        } 
        else if (datas.competition.type_ranking=="puissance"){
            for (let i = 0 ; i < 6; i++) {
                if (datas.ranking[i]){
                    col1.push(datas.ranking[i])
                }
              }
              for (let i = 6 ; i < 12; i++) {
                  if (datas.ranking[i]){
                      col2.push(datas.ranking[i])
                  }
              }
        } 
        else if (datas.competition.type_ranking=="championnat"){
            for (let i = 0 ; i < 7; i++) {
                if (datas.ranking[i]){
                    col1.push(datas.ranking[i])
                }
              }
              for (let i = 7 ; i < 14; i++) {
                  if (datas.ranking[i]){
                      col2.push(datas.ranking[i])
                  }
              }

        } 
        else {
            if (datas.teams && datas.teams.length>0 ){
                for (let i = 0 ; i < 9; i++) {
                    if (datas.ranking[i]){
                        col1.push(datas.ranking[i])
                    }
                  }
                  for (let i = 9 ; i < 18; i++) {
                      if (datas.ranking[i]){
                          col2.push(datas.ranking[i])
                      }
                  }
            } else {
                for (let i = 0 ; i < 10; i++) {
                    if (datas.ranking[i]){
                        col1.push(datas.ranking[i])
                    }
                }
                if (datasNext && datasNext.list){                    
                    for (let i = 0 ; i < 10; i++) {
                        if (datasNext.list[i]){
                            col2.push(datasNext.list[i])
                        }
                    }  
                } else {
                    for (let i = 10 ; i < 20; i++) {
                        if (datas.ranking[i]){
                            col2.push(datas.ranking[i])
                        }
                    }                      
                }
            }
    
        }

    }
    useEffect(() => {
        applyFontStretch(".speak_title_text")
        applyFontStretch(".speak_line-res-pointsTxt")        
    },[datas])

    useInterval(() => {            
        if(datas.countDown && datas.countDown.end){
            var start = moment().format('HH:mm:ss');    
            var end = datas.countDown.end;
            var diff = moment.duration(moment(end, "HH:mm:ss a").diff(moment(start, "HH:mm:ss a")));
            var time = ""
            if (diff.asSeconds()>=0){
                if (diff.hours()>0){
                    time = time+diff.hours().toString().padStart(2, '0')+":"
                }
                if (diff.minutes()>0){
                    time = time+diff.minutes().toString().padStart(2, '0')+":"
                }
                time = time + diff.seconds().toString().padStart(2, '0')    
            } else {
                time="00"
            }
            setCountDown(time);
        }else{
            setCountDown(null);
        }       
      }, 500); 
      
    return (
        <>
        <div style={{ background:"linear-gradient(#073E60,transparent)",backgroundColor:"#ffffff",width:'100%',height:'100%'}}>
        <div className="speak_title">
            <Container fluid>
                <Row>
                <Col xs={10}>
                    <div className="speak_title_text">
                        <p>{datas.competition.name}</p>
                    </div>
                </Col>
                <Col xs={2} className="speak_time-table">
                    <div className="speak_title_time">
                        {time}
                    </div>
                </Col>
                </Row>
            </Container>
        </div>

         <Container fluid className="speak_leaders">
            <Row>
                <Col xs={4}>
                {datas.competition.type_ranking=="normal"?<div className="speak_standings-title">Standings</div>:null}
                <Container fluid className="speak_table-results">
                    
                    {col1.map((d,index)=>{
                            return(
                                <div key={index} >
                                {datas.competition.type_ranking == "normal" && <TableLine key={index} index={index} entry={d} />}
                                {datas.competition.type_ranking == "team" && <TableLineTeam key={index} index={index} entry={d} />}
                                {datas.competition.type_ranking == "puissance" && <TableLinePuissance key={index} index={index} entry={d} />}
                                {datas.competition.type_ranking == "championnat" && <TableLineChampionnat key={index} index={index} entry={d} />}
                                </div>
                            )
                        })}
                </Container>
                </Col>
                <Col xs={4}>
                {datas.competition.type_ranking=="normal"?<div className="speak_standings-title">{datasNext?'Next Riders':'Standings'}</div>:null}
                <Container fluid className="speak_table-results">
                    {col2.map((d,index)=>{
                            return(
                                <div key={index} >
                                {datas.competition.type_ranking == "normal" && <TableLine key={index} index={index} entry={d} />}
                                {datas.competition.type_ranking == "team" && <TableLineTeam key={index} index={index} entry={d} />}
                                {datas.competition.type_ranking == "puissance" && <TableLinePuissance key={index} index={index} entry={d} />}
                                {datas.competition.type_ranking == "championnat" && <TableLineChampionnat key={index} index={index} entry={d} />}
                                </div>
                            )
                        })}
                </Container>
                </Col>
                <Col xs={4}>
                    <br/>
                    {datas.live || datas.last ?
                    <>
                    <Container fluid className="speak_table-live">
                        <Row >
                            <Col xs={12} className="speak_last-title">   
                                Live
                            </Col>
                        </Row>
                        {datas.live ?
                        <>
                        <Row>
                            <Col xs={10} className="speak_last-cav-horse">
                                {datas.live.rider}<br/><span className="speak_last-horse">{datas.live.hnb} {datas.competition.isInter ? '(' + datas.live.order + ') ':null}- {datas.live.horse}</span>
                            </Col>
                            <Col xs={2} style={{paddingTop:"10px"}}>
                                {datas.live.nation && <img alt="drapeau" width="70px" src={"/img/flags/"+datas.live.nation+'.png'}/>}
                            </Col>
                        </Row>
                        <Row className="speak_rowResultsLive">
                            <Col xs={2} className="speak_last-cav-horse speak_alignCenter speak_small-score">
                                {(datas.live.results && datas.live.results.time1) && 
                                    <>
                                    {datas.live.results.time1}
                                    <br/>
                                    {datas.live.results.fencePen1}+{datas.live.results.timePen1}={datas.live.results.totalPen1}
                                    </>
                                }
                            </Col>
                            <Col xs={2} className="speak_last-cav-horse speak_sub-score speak_alignCenter speak_small-score">
                                {(datas.live.results && datas.live.results.time2) &&
                                <>
                                    {datas.live.results.time2}
                                    <br/>
                                    {datas.live.results.fencePen2}+{datas.live.results.timePen2}={datas.live.results.totalPen2}
                                </>
                                }
                            </Col>
                            <Col xs={3} className="speak_last-cav-horse speak_chrono speak_alignCenter">
                                {(datas.live.results && (datas.live.results.totalPen == 'EL' || datas.live.results.totalPen == 'RET'))?<><br/>{datas.live.results.totalPen}</>:<>{datasTiming.countDown ? datasTiming.countDownValue : datasTiming.time}<br/>{datasTiming.totalFaults}</>}  
                            </Col>
                            <Col xs={2} className="speak_last-cav-horse speak_alignCenter">
                            {(datas.live.results && datas.live.results.entryRank) ? <>Rank<br/>{datas.live.results.entryRank+'.'}</>:<>&nbsp;<br/>&nbsp;</>}
                            </Col>
                            <Col xs={3} className="speak_last-cav-horse speak_alignCenter">
                                {datas.timeDiff && 
                                    <>
                                    {datas.timeDiff > 0 ? 
                                    <><Badge bg="danger">{'+'+datas.timeDiff}</Badge></>
                                    :
                                    <><Badge bg="success">{datas.timeDiff}</Badge></>} 
                                </>}
                            </Col>
                        </Row>
                        <Row className="speak_rowResultsLive">
                            <Col xs={12} className="speak_last-cav-horse speak_alignCenter">
                                {(datas.live.results && datas.live.results.entryRank) && datas.live.results.extraPoints}
                                {datasStats && datasStats.alogo && datasStats.alogo.live && <AlogoLive alogoLive={datasStats}/>}
                            </Col>
                        </Row>
                        </>
                        :
                        <>
                        <Row>
                            <Col xs={12} className="speak_last-cav-horse">
                            &nbsp;<br/><span className="speak_last-horse">&nbsp;</span>
                            </Col>
                        </Row>
                        <Row className="speak_rowResultsLive">
                            <Col xs={12} className="speak_last-cav-horse speak_alignCenter">
                            &nbsp;<br/>&nbsp;
                            </Col>

                        </Row>
                        </>
                        }
                    </Container>
                    <br/>
                    <Container fluid className="speak_table-last">
                        <Row >
                            <Col xs={12} className="speak_last-title">   
                                Last
                            </Col>
                        </Row>
                        {datas.last ?
                        <>
                        <Row>
                            <Col xs={10} className="speak_last-cav-horse">
                                {datas.last.rider}<br/><span className="speak_last-horse">{datas.last.hnb} {datas.competition.isInter ? '(' + datas.last.order + ') ':null}- {datas.last.horse}</span>
                            </Col>
                            <Col xs={2} style={{paddingTop:"10px"}}>
                                {datas.last.nation && <img alt="drapeau" width="70px" src={"/img/flags/"+datas.last.nation+'.png'}/>}
                            </Col>
                        </Row>
                        <Row className="speak_rowResults speak_rowResultsLast">
                            <Col xs={2} className="speak_last-cav-horse speak_alignCenter speak_small-score">
                                {datas.last.results.time1 && 
                                    <>
                                    {datas.last.results.time1}
                                    <br/>
                                    {datas.last.results.fencePen1}+{datas.last.results.timePen1}={datas.last.results.totalPen1}
                                    </>
                                }
                            </Col>
                            <Col xs={2} className="speak_last-cav-horse speak_sub-score speak_alignCenter speak_small-score">
                                {datas.last.results.time2 &&
                                <>
                                    {datas.last.results.time2}
                                        <br/>
                                    {datas.last.results.fencePen2}+{datas.last.results.timePen2}={datas.last.results.totalPen2}
                                </>
                                }
                            </Col>
                            <Col xs={3} className="speak_last-cav-horse speak_chrono speak_alignCenter">
                                {datas.last.results.totalTime}<br/>{datas.last.results.totalPen}
                            </Col>
                            <Col xs={2} className="speak_last-cav-horse speak_alignCenter">
                                {datas.last.results.entryRank ? <>Rank<br/>{datas.last.results.entryRank+'.'}</>:<>&nbsp;<br/>&nbsp;</>}
                            </Col>
                            <Col xs={3} className="speak_last-cav-horse speak_alignCenter">
                                
                            </Col>
                        </Row>
                        <Row className="speak_rowResults">
                            <Col xs={12} className="speak_last-cav-horse speak_alignCenter">
                            {datas.last.results.entryRank && datas.last.results.extraPoints}
                            </Col>
                        </Row>

                        </>
                        :
                        <>
                        <Row>
                            <Col xs={12} className="speak_last-cav-horse">
                            &nbsp;<br/><span className="speak_last-horse">&nbsp;</span>
                            </Col>
                        </Row>
                        <Row className="speak_rowResults speak_rowResultsLast">
                            <Col xs={12} className="speak_last-cav-horse speak_alignCenter">
                                &nbsp;<br/>&nbsp;
                            </Col>
                        </Row>
                        </>                        
                        }
                    </Container>
                    <br/>
                    </>:null}
                    <Container fluid className="speak_table-stats">
                        <Row >
                            <Col xs={3} className="speak_stats-title">   
                                Total
                            </Col>
                            <Col xs={3} className="speak_stats-title">   
                                Left
                            </Col>
                            <Col xs={3} className="speak_stats-title">   
                                Past
                            </Col>
                            <Col xs={3} className="speak_stats-title">   
                                Clear
                            </Col>
                        </Row>
                        <Row className="">
                            <Col xs={3} className="speak_stats-core">   
                                {datas.competition.total}
                            </Col>
                            <Col xs={3} className="speak_stats-core">   
                                {datas.competition.left}
                            </Col>
                            <Col xs={3} className="speak_stats-core">   
                                {datas.competition.past}
                            </Col>
                            <Col xs={3} className="speak_stats-core">   
                                {datas.competition.clear} <span style={{fontSize:"30px"}}>({datas.competition.perc_clear}%)</span>
                            </Col>
                        </Row>
                    </Container>
                    <br/>
                    <Container fluid className="speak_table-stats">
                        <Row >
                            <Col xs={4} className="speak_stats-title">   
                                Allowed
                            </Col>
                            <Col xs={4} className="speak_stats-title">   
                                Rythm
                            </Col>
                            <Col xs={4} className="speak_stats-title">   
                                Finish
                            </Col>
                        </Row>
                        <Row className="">
                            <Col xs={4} className="speak_stats-core speak_stats-allowed">   
                                {datas.competition.timeAllowed}
                            </Col>
                            <Col xs={4} className="speak_stats-core">   
                                {datas.competition.rythme && <>{datas.competition.rythme}/h</>}
                            </Col>
                            <Col xs={4} className="speak_stats-core">   
                                {datas.competition.finish}
                            </Col>
                        </Row>
                    </Container>

                    {datasStats && datasStats.alogo && datasStats.alogo.obstacles?<AlogoStats alogoStats={datasStats.alogo}/>:null}
                    {countDown && 
                        <>
                            <br/>
                            <div className="speak_countDown">{countDown}</div>
                        </>
                    }
                    {/* <br/>
                    <div className="speak_table-stats alignCenter" style={{paddingTop:'8px',paddingBottom:'8px'}}>
                        <img alt="logo" width="175px" src="/img/wst.svg"/>
                    </div> */}
                    <br/>
                   
                </Col>

            </Row>
        </Container> 
        {datas.teams && datas.teams.length>0 ?<MiniTeams teams={datas.teams}/>:null}
        
        </div>
        </>
      );
  }

  export default SpeakerSo;

import React from "react";
import Badge from 'react-bootstrap/Badge';

function TvSo01(props) {
    if (props){
        var idArena = props.idArena;
        var datas = props.datas;
        var datasTiming = props.datasTiming;
        var sceneState = props.sceneState
    }

    if (props.specificGFX){
        var urlGFX = '/img/events/'+idArena+'/tv/'
    } else {
        var urlGFX = '/img/tv/'
    }     
    
    return (
        <>
            {/* <div style={{ backgroundImage: 'url('+urlGFX+'fond.png)',height:'100%',width:'100%'}}> */}
                {datas.live &&
                <>
                {(sceneState=="presentation" || sceneState=="resultat")?
                    <div className="tv_presentation" style={{ backgroundImage: 'url('+urlGFX+'tv-presentation.png)',height:'100%',width:'100%'}}>
                        <div className="tv_rider">
                            <span className="tv_fistname">{datas.live.firstname}</span>&nbsp;{datas.live.lastname}
                        </div>
                        <div className="tv_horse">
                            {datas.live.horse}
                        </div>
                        <div className="tv_flag">
                            {datas.live.nation &&
                                <img alt="drapeau" width="30px" src={"/img/tv/flags/"+datas.live.nation+'.png'}/>
                            }
                        </div>
                        <div className="tv_nation">
                            {datas.live.nation}
                        </div>
                    </div>                        
                :null}
                {(sceneState=="live")?
                <>
                    <div className="tv_timeback" style={{ backgroundImage: 'url('+urlGFX+'tv-time.png)',height:'100%',width:'100%'}}>
                        <div className="tv_time">
                            {(datas.live.results.totalPen == 'RET' || datas.live.results.totalPen == 'EL') ?<>{datas.live.results.totalPen}</>:<>{datasTiming.countDown ? datasTiming.countDownValue : datasTiming.time}</>}
                        </div>
                    </div>
                    {datasTiming.totalFaults>0 &&
                        <div className="tv_penback" style={{ backgroundImage: 'url('+urlGFX+'tv-pen.png)',height:'100%',width:'100%'}}>
                            <div className="tv_pen">
                                {(datas.live.results.totalPen == 'RET' || datas.live.results.totalPen == 'EL') ?<>{datas.live.results.totalPen}</>:<>{datasTiming.totalFaults}</>}
                            </div>
                        </div>
                    }
                </>
                :null}
                {(sceneState=="live")?
                <>
                    <div className="tv_live" style={{ backgroundImage: 'url('+urlGFX+'tv-live.png)',height:'100%',width:'100%'}}>
                        <div className="tv_riderlive">
                            {datas.live.firstname.slice(0,1)}. {datas.live.lastname}
                        </div>
                        <div className="tv_horselive">
                            {datas.live.horse}
                        </div>
                        <div className="tv_flaglive">
                            {datas.live.nation &&
                                <img alt="drapeau" width="30px" src={"/img/tv/flags/"+datas.live.nation+'.png'}/>
                            }
                        </div>
                        <div className="tv_nationlive">
                            {datas.live.nation}
                        </div>
                    </div> 
                </>                                            
                :null}

                {(datas.timeDiff && sceneState=="live") && 
                    <>
                    {datas.timeDiff > 0 ?                         
                    <div className="tv_ecartback" style={{ backgroundImage: 'url('+urlGFX+'tv-ecart-rouge.png)',height:'100%',width:'100%'}}>
                        <div className="tv_ecart">
                            {'+'+datas.timeDiff}
                        </div>
                    </div>                        
                    :                        
                    <div className="tv_ecartback" style={{ backgroundImage: 'url('+urlGFX+'tv-ecart-vert.png)',height:'100%',width:'100%'}}>
                        <div className="tv_ecart">
                            {datas.timeDiff}
                        </div>
                    </div>
                    }                     
                    </>
                }                    


                {(sceneState=="resultat")?
                <>
                    <div className="tv_pointsback" style={{ backgroundImage: 'url('+urlGFX+'tv-points.png)',height:'100%',width:'100%'}}>
                        <div className="tv_points">
                            {datas.live.results.totalPen == 'RET' && 'Retired'}
                            {datas.live.results.totalPen == 'EL' && 'Eliminated'}
                            {(datas.live.results.totalPen != 'RET' && datas.live.results.totalPen != 'EL') && <>{'Pen: '+datas.live.results.totalPen}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'Time: '+datas.live.results.totalTime}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'Rank: '+datas.live.results.entryRank+'.'}</>}
                        </div>
                    </div>
                </>                        
                :null}                
                </>
                }

        </>
      );
  }

  export default TvSo01;

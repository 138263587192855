import React, { useState, useEffect, useRef } from "react";
import {applyFontStretch} from '../../functions/strech.js'

function GiantSo01(props) {
    const [ stylePath, setStylePath ] = useState(null);
    useEffect(() => {    
        if (stylePath){
            var head = document.head;
            var link = document.createElement("link");
        
            link.type = "text/css";
            link.rel = "stylesheet";
            link.href = stylePath;
        
            head.appendChild(link);    
            console.log(link)
            return () => { head.removeChild(link); }
        }
        
      }, [stylePath]);
      useEffect(() => {
        console.log('specific css '+props.specificCSS)
        if (props.specificCSS){
            var urlCSS = '/img/events/'+idArena+'/giant_full_ranking/giant_full_ranking_specific.css'
            setStylePath(urlCSS)
        }
      }, [props.specificCSS]);

      useEffect(() => {
        applyFontStretch(".giant_full_ranking-mini-ranking-line-res")        
    },[datas])

    if (props){
        var idArena = props.idArena;
        var datas = props.datas;
        var datasTiming = props.datasTiming;
        var isOrigins = props.origins;
    }

    if (props.specificGFX){
        var urlGFX = '/img/events/'+idArena+'/giant_full_ranking/'
    } else {
        var urlGFX = '/img/giant_full_ranking/'
    }     

    if (props.specificFlag){
        var urlFlag = '/img/events/'+idArena+'/giant_full_ranking/flags/'
    } else {
        var urlFlag = "/img/flags/"
    }    

    var nbFirst=0;
    var indexDebut=0;
    var indexFin=2;
    if(datas.ranking){
        datas.ranking.forEach(d => {
            if (d.results){
                if (d.results.entryRank == 1){
                    nbFirst = nbFirst + 1;
                }
            }
        });
    }
    if (nbFirst>3){
        indexFin = nbFirst-1;
        indexDebut=indexFin-2;
    }

    return (
        <>
            <div style={{ backgroundImage: 'url('+urlGFX+'full2.png)',height:'100%',width:'100%'}}>
                {datas.live &&
                <>
                <div className="giant_full_ranking-rider">
                    {datas.live.rider}
                </div>
                <div className="giant_full_ranking-horse">
                    {datas.competition.isInter ? datas.live.order:datas.live.hnb} - {datas.live.horse}
                </div>

                <div className="giant_full_ranking-flag">
                    {datas.live.nation &&<>
                        <img className="giant_full_ranking-flag-drapeau" alt="drapeau" src={urlFlag+datas.live.nation+'.png'}/>                    
                        <div className="giant_full_ranking-flag-text">{datas.live.nation}</div></>
                    }
                </div>
                <div className="giant_full_ranking-chrono">
                    {datas.live.results.totalPen == 'RET' || datas.live.results.totalPen == 'EL' ?<></>:<>{datasTiming.countDown ? datasTiming.countDownValue : datasTiming.time}</>}
                </div>

                <div className="giant_full_ranking-points">
                    {datas.live.results.totalPen == 'RET' || datas.live.results.totalPen == 'EL' ?<>{datas.live.results.totalPen}</>:<>{datasTiming.totalFaults}</>}
                </div>

                {(datas.live.results.extraPoints || datas.live.results.entryRank) &&
                    <>
                        {datas.live.results.extraPoints ?null:
                        <div className="giant_full_ranking-rank" style={{ backgroundImage: "url("+urlGFX+"full2-rank.png)"}}>
                            {datas.live.results.entryRank ? 
                                <><div className="giant_full_ranking-rank-text">{'Rank '+datas.live.results.entryRank+'.'}</div></>
                            :null}
                        </div>}
                    </>
                }
                {datas.live.results.extraPoints &&
                    <div className="giant_full_ranking-extrapoints">
                        {datas.live.results.extraPoints}
                    </div>
                }
                {((datasTiming.fenceFaults || datasTiming.timeFaults) && datas.competition.activeDetailFaults) &&
                    <div className="giant_full_ranking-extrapoints">
                        {datasTiming.fenceFaults>=0 && 'Obst.: ' + datasTiming.fenceFaults}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{datasTiming.timeFaults>=0 && 'Time: '+datasTiming.timeFaults}
                    </div>
                }
                {datas.timeDiff && 
                    <>
                    {datas.timeDiff > 0 ?
                    <>
                        <div className="giant_full_ranking-ecart" style={{ backgroundImage: "url("+urlGFX+"full2-rouge.png)"}}>
                            <div className="giant_full_ranking-ecart-text">
                                {'+'+datas.timeDiff}
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="giant_full_ranking-ecart" style={{ backgroundImage: "url("+urlGFX+"full2-vert.png)"}}>
                            <div className="giant_full_ranking-ecart-text">
                                {datas.timeDiff}
                            </div>
                        </div>                    
                    </>} 
                    </>
                }
                {isOrigins ?
                <>
                    <div className="giant_full_ranking-pere">
                        {datas.live.sire && 'Sire: '+datas.live.sire}
                    </div>
                    <div className="giant_full_ranking-mere">
                        {datas.live.dam && 'Dam: '+datas.live.dam}
                    </div>
                </>
                :                
                <div className="giant_full_ranking-mini-ranking-lines">
                    {datas.ranking.map((d,index)=>{
                        if (index <= indexFin && index >= indexDebut){
                            return(
                                <>
                                {d.results?
                                <div key={index} className="giant_full_ranking-mini-ranking-line">
                                    <div className="giant_full_ranking-mini-ranking-line-rank">
                                        {d.results.entryRank?d.results.entryRank+'.':null}
                                    </div>
                                    <div className="giant_full_ranking-mini-ranking-line-rider">
                                        {d.rider}
                                    </div>
                                    <div className="giant_full_ranking-mini-ranking-line-rider-short">
                                        {d.firstname && d.firstname.slice(0,1)+'.'+d.lastname}
                                    </div>                                    
                                    <div className="giant_full_ranking-mini-ranking-line-res">
                                        {d.results.totalPen}{d.results.totalTime && <> / {d.results.totalTime}</>}
                                    </div>
                                    <div className="giant_full_ranking-mini-ranking-flag">
                                        {d.nation &&
                                            <img className="giant_full_ranking-mini-ranking-flag-drapeau" alt="drapeau" src={urlFlag+d.nation+'.png'}/>
                                        }
                                    </div>
                                </div>
                                :null}
                                {d.members?
                                <div key={index} className="giant_full_ranking-mini-ranking-line">
                                    <div className="giant_full_ranking-mini-ranking-line-rank">
                                        {d.entryRank?d.entryRank+'.':null}
                                    </div>
                                    <div className="giant_full_ranking-mini-ranking-line-rider">
                                        {d.name}
                                    </div>
                                    <div className="giant_full_ranking-mini-ranking-line-res">
                                        {d.total}{d.time>0 && <> / {d.time}</>}
                                    </div>
                                    <div className="giant_full_ranking-mini-ranking-flag">
                                        {d.nation &&
                                            <img alt="drapeau" width="150px" src={"/img/flags/"+d.nation+'.png'}/>
                                        }
                                    </div>
                                </div>
                                :null}
                                {d.res?
                                <div key={index} className="giant_full_ranking-mini-ranking-line">
                                    <div className="giant_full_ranking-mini-ranking-line-rank">
                                        {d.entryRank?d.entryRank+'.':null}
                                    </div>
                                    <div className="giant_full_ranking-mini-ranking-line-rider">
                                        {d.rider}
                                    </div>
                                    <div className="giant_full_ranking-mini-ranking-line-res">
                                        {d.total}
                                    </div>
                                    <div className="giant_full_ranking-mini-ranking-flag">
                                        {d.nation &&
                                            <img alt="drapeau" width="150px" src={"/img/flags/"+d.nation+'.png'}/>
                                        }
                                    </div>
                                </div>
                                :null}
                                </>      
                            )        
                        } else {
                            return (null)
                        }
                    })}
                </div> 
                }
                </>
                }
            </div>
        </>
      );
  }

  export default GiantSo01;

import React, { useState, useEffect, useRef } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import moment from 'moment/min/moment-with-locales'
import TableLine from "./TableLine";
import {useInterval} from '../../Functions'


function SpeakerDr(props) {
    if (props){
        var idArena = props.idArena;
        var datas = props.datas;
        var datasDrLive = props.datasDrLive;
    }
    const[time,setTime] = useState("99:99:99");

    useInterval(() => {
        setTime(moment().format('HH:mm:ss'));
      }, 100);

    var col1=[]
    var col2=[]
    if (datas.ranking){        
        for (let i = 0 ; i < 7; i++) {
            if (datas.ranking[i]){
                col1.push(datas.ranking[i])
            }
            }
            for (let i = 7 ; i < 14; i++) {
                if (datas.ranking[i]){
                    col2.push(datas.ranking[i])
                }
            }
    }
    if (datasDrLive){
        if (!datasDrLive.rank>0){
            datasDrLive=null
        }
    }
    return (
        <>
        <div style={{ background:"linear-gradient(#073E60,transparent)",backgroundColor:"#ffffff",width:'100%',height:'100%'}}>
        <div className="speak_title">
            <Container fluid>
                <Row>
                <Col xs={10}>
                    {datas.competition.name}
                </Col>
                <Col xs={2} className="speak_time-table">
                    {time}
                </Col>
                </Row>
            </Container>
        </div>

         <Container fluid className="speak_leaders">
            <Row>
                <Col xs={4}>
                <br/>
                <Container fluid className="speak_table-results">
                    {col1.map((d,index)=>{
                            return(
                                <>
                                {datas.competition.type_ranking == "normal" && <TableLine key={index} index={index} entry={d} />}
                                </>
                            )
                        })}
                </Container>
                </Col>
                <Col xs={4}>
                <br/>
                <Container fluid className="speak_table-results">
                    {col2.map((d,index)=>{
                            return(
                                <>
                                {datas.competition.type_ranking == "normal" && <TableLine key={index} index={index} entry={d} />}
                                </>
                            )
                        })}
                </Container>
                </Col>
                <Col xs={4}>
                    <br/>
                    {datas.live || datas.last ?
                    <>
                    <Container fluid className="speak_table-live">
                        <Row >
                            <Col xs={12} className="speak_last-title">   
                                Live
                            </Col>
                        </Row>
                        {datas.live ?
                        <>
                        <Row>
                            <Col xs={10} className="speak_last-cav-horse">
                                {datas.live.rider}<br/><span className="speak_last-horse">{datas.live.hnb} {datas.competition.isInter ? '(' + datas.live.order + ') ':null}- {datas.live.horse}</span>
                            </Col>
                            <Col xs={2} style={{paddingTop:"10px"}}>
                                {datas.live.nation && <img alt="drapeau" width="70px" src={"/img/flags/"+datas.live.nation+'.png'}/>}
                            </Col>
                        </Row>
                        <Row className="speak_rowResultsLive">
                            <Col xs={3} className="speak_last-cav-horse speak_alignCenter">
                            {datasDrLive && <>Total<br/> {datasDrLive.trend+'%'}</>}
                            </Col>
                            <Col xs={3} className="speak_last-cav-horse speak_chrono speak_alignCenter">
                                
                            </Col>
                            <Col xs={2} className="speak_last-cav-horse speak_alignCenter">
                            {datasDrLive ? <>Rank<br/>{datasDrLive.rank+'.'}</>:<>&nbsp;<br/>&nbsp;</>}
                            </Col>
                            <Col xs={3} className="speak_last-cav-horse speak_alignCenter">

                            </Col>

                        </Row>
                        <Row className="speak_rowResultsLive">
                            <Col xs={12} className="speak_last-cav-horse speak_alignCenter">
                                {datas.live.results.entryRank && datas.live.results.extraPoints}
                            </Col>
                        </Row>
                        </>
                        :
                        <>
                        <Row>
                            <Col xs={12} className="speak_last-cav-horse">
                            &nbsp;<br/><span className="speak_last-horse">&nbsp;</span>
                            </Col>
                        </Row>
                        <Row className="speak_rowResultsLive">
                            <Col xs={12} className="speak_last-cav-horse speak_alignCenter">
                            &nbsp;<br/>&nbsp;
                            </Col>

                        </Row>
                        </>
                        }
                    </Container>
                    <br/>
                    <Container fluid className="speak_table-last">
                        <Row >
                            <Col xs={12} className="speak_last-title">   
                                Last
                            </Col>
                        </Row>
                        {datas.last ?
                        <>
                        <Row>
                            <Col xs={10} className="speak_last-cav-horse">
                                {datas.last.rider}<br/><span className="speak_last-horse">{datas.last.hnb} {datas.competition.isInter ? '(' + datas.last.order + ') ':null}- {datas.last.horse}</span>
                            </Col>
                            <Col xs={2} style={{paddingTop:"10px"}}>
                                {datas.last.nation && <img alt="drapeau" width="70px" src={"/img/flags/"+datas.last.nation+'.png'}/>}
                            </Col>
                        </Row>
                        <Row className="speak_rowResults speak_rowResultsLast">
                            <Col xs={3} className="speak_last-cav-horse speak_alignCenter">
                                
                            </Col>
                            <Col xs={3} className="speak_last-cav-horse speak_alignCenter">
                            {datas.last.results.total>0 && <>Total<br/>{datas.last.results.total + '%'}</>}
                            </Col>
                            <Col xs={3} className="speak_last-cav-horse speak_alignCenter">
                                {datas.last.results.entryRank ? <>Rank<br/>{datas.last.results.entryRank+'.'}</>:<>&nbsp;<br/>&nbsp;</>}
                            </Col>
                            <Col xs={3} className="speak_last-cav-horse speak_alignCenter">
                                
                            </Col>
                        </Row>
                        <hr/>
                        <Row className="speak_rowResults speak_rowResultsLast">
                            <Col xs={1} className="speak_last-cav-horse speak_alignCenter speak_small-score"></Col>    
                            <Col xs={2} className="speak_last-cav-horse speak_alignCenter speak_small-score">
                                {(datas.last.results.totalE && datas.last.results.totalE > 0) && <>E<br/>{datas.last.results.totalE}</>}
                            </Col>
                            <Col xs={2} className="speak_last-cav-horse speak_alignCenter speak_small-score">
                                {(datas.last.results.totalH && datas.last.results.totalH > 0) && <>H<br/>{datas.last.results.totalH}</>}
                            </Col>
                            <Col xs={2} className="speak_last-cav-horse speak_alignCenter speak_small-score">
                                {(datas.last.results.totalC && datas.last.results.totalC > 0) && <>C<br/>{datas.last.results.totalC}</>}
                            </Col>
                            <Col xs={2} className="speak_last-cav-horse speak_alignCenter speak_small-score">
                                {(datas.last.results.totalM && datas.last.results.totalM > 0) && <>M<br/>{datas.last.results.totalM}</>}
                            </Col>
                            <Col xs={2} className="speak_last-cav-horse speak_alignCenter speak_small-score">
                                {(datas.last.results.totalB && datas.last.results.totalB > 0) && <>B<br/>{datas.last.results.totalB}</>}
                            </Col>
                            <Col xs={1} className="speak_last-cav-horse speak_alignCenter"></Col>
                        </Row>
                        </>
                        :
                        <>
                        <Row>
                            <Col xs={12} className="speak_last-cav-horse">
                            &nbsp;<br/><span className="speak_last-horse">&nbsp;</span>
                            </Col>
                        </Row>
                        <Row className="speak_rowResults speak_rowResultsLast">
                            <Col xs={12} className="speak_last-cav-horse speak_alignCenter">
                                &nbsp;<br/>&nbsp;
                            </Col>
                        </Row>
                        </>                        
                        }
                    </Container>
                    <br/>
                    </>:null}
                    <Container fluid className="speak_table-stats">
                        <Row >
                            <Col xs={4} className="speak_stats-title">   
                                Total
                            </Col>
                            <Col xs={4} className="speak_stats-title">   
                                Left
                            </Col>
                            <Col xs={4} className="speak_stats-title">   
                                Past
                            </Col>
                        </Row>
                        <Row className="">
                            <Col xs={4} className="speak_stats-core">   
                                {datas.competition.total}
                            </Col>
                            <Col xs={4} className="speak_stats-core">   
                                {datas.competition.left}
                            </Col>
                            <Col xs={4} className="speak_stats-core">   
                                {datas.competition.past}
                            </Col>
                        </Row>
                    </Container>
                    <br/>
                    {/* <br/>
                    <div className="speak_table-stats alignCenter" style={{paddingTop:'8px',paddingBottom:'8px'}}>
                        <img alt="logo" width="175px" src="/img/wst.svg"/>
                    </div> */}
                </Col>

            </Row>
        </Container> 
        </div>
        </>
      );
  }

  export default SpeakerDr;

import React, { useState, useEffect, useRef } from "react";

function GiantSoAlogo(props) {
    const [ stylePath, setStylePath ] = useState(null);
    useEffect(() => {    
        if (stylePath){
            var head = document.head;
            var link = document.createElement("link");
        
            link.type = "text/css";
            link.rel = "stylesheet";
            link.href = stylePath;
        
            head.appendChild(link);    
            console.log(link)
            return () => { head.removeChild(link); }
        }
        
      }, [stylePath]);
      useEffect(() => {
        console.log('specific css '+props.specificCSS)
        if (props.specificCSS){
            var urlCSS = '/img/events/'+idArena+'/giant_full_ranking/giant_full_ranking_specific.css'
            setStylePath(urlCSS)
        }
      }, [props.specificCSS]);

    if (props){
        var idArena = props.idArena;
        var datasStats = props.datasStats;        
    }

    if (props.specificGFX){
        var urlGFX = '/img/events/'+idArena+'/giant_full_ranking/'
    } else {
        var urlGFX = '/img/giant_full_ranking/'
    }     

    return (
        <>
            <div style={{ backgroundImage: 'url('+urlGFX+'stats-fond.png)',height:'100%',width:'100%'}}>
                {datasStats && datasStats.alogo && datasStats.alogo.obstacles &&
                    <>
                        <div className="giant_full_ranking-stats-title">
                            Most Knocked Obstacles
                        </div> 
                        <div className="giant_full_ranking-stats-title2">
                            Powered by Alogo Analysis
                        </div> 

                        <div className="giant_full_ranking-stats-lines">
                            {datasStats.alogo.obstacles.map((d,index)=>{
                                if (index<5){
                                    return(
                                        <div key={index} className="giant_full-ranking-stats-line" style={{ backgroundImage: index>0 ?'url('+urlGFX+'stats-line.png)':null,backgroundRepeat:'no-repeat'}}>
                                            <div className="giant_full-ranking-stats-number">{d.ObstName}</div>
                                            <div className="giant_full-ranking-stats-type">{d.ObstType}</div>
                                            <div className="giant_full-ranking-stats-count">{d.ObstKnockedCount}x</div>
                                            <div className="giant_full-ranking-stats-percent">{d.ObstKnockedPercent}%</div>
                                        </div>
                                    )    
                                }
                            })}
                        </div>
                    </>
                }
            </div>
        </>
      );
  }

  export default GiantSoAlogo;

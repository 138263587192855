import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class TableLine extends React.Component {

    render(){
        var classFond
        if (this.props.index%2==0){
            classFond = "speak_fond_impair"
        } else {
            classFond = "speak_fond_pair"
        }
    return(
        <>
        {this.props.entry?
        <Row className={this.props.entry.hnb == this.props.liveHnb?"speak_line-res" + " speak_table-stats":"speak_line-res "+classFond}>
            <Col xs={1} className="speak_line-res-cont-rank">   
                <span className="speak_line-res-rank">{this.props.entry.results.entryRank>0?this.props.entry.results.entryRank+'.':null}</span> 
            </Col>
            <Col xs={8} className="speak_line-res-cav-horse">
                {this.props.entry.rider}<br/><span className="speak_line-res-horse">{this.props.entry.hnb} - {this.props.entry.horse}</span>

            </Col>
            <Col xs={3} className="speak_line-res-points speak_alignCenter">
                {this.props.entry.results.total > 0 && this.props.entry.results.total+'%'}<br/>
                {this.props.entry.nation && <img alt="drapeau" width="70px" src={"/img/flags/"+this.props.entry.nation+'.png'}/>}
            </Col>
            <Col>
            <hr className="speak_line-small-line"/>
                <div className="speak_line-res-detail-judges">
                    {(this.props.entry.results.totalE && this.props.entry.results.totalE > 0) && <>E: {this.props.entry.results.totalE}</>}&nbsp;&nbsp;&nbsp;
                    {(this.props.entry.results.totalH && this.props.entry.results.totalH > 0)&& <>H: {this.props.entry.results.totalH}</>}&nbsp;&nbsp;&nbsp;
                    {(this.props.entry.results.totalC && this.props.entry.results.totalC > 0) && <>C: {this.props.entry.results.totalC}</>}&nbsp;&nbsp;&nbsp;
                    {(this.props.entry.results.totalM && this.props.entry.results.totalM > 0) && <>M: {this.props.entry.results.totalM}</>}&nbsp;&nbsp;&nbsp;
                    {(this.props.entry.results.totalB && this.props.entry.results.totalB > 0) && <>B: {this.props.entry.results.totalB}</>}
                </div>
            </Col>
        </Row>
        :null}
        </>
)
}
}
export default TableLine;

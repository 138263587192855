import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class TableLine extends React.Component {

    render(){
        var classFond
        if (this.props.index%2==0){
            classFond = "speak_fond_impair"
        } else {
            classFond = "speak_fond_pair"
        }
    return(
        <>
        {this.props.entry?
        <Row className={this.props.entry.hnb == this.props.liveHnb?"speak_line-res" + " speak_table-stats":"speak_line-res "+classFond}>
            <Col xs={1} className="speak_line-res-cont-rank">   
                <span className="speak_line-res-rank">{this.props.entry.results.entryRank>0?this.props.entry.results.entryRank+'.':null}</span> 
            </Col>
            <Col xs={8} className="speak_line-res-cav-horse">
                <div className="speak_line-res-cav">
                    {this.props.entry.rider}
                </div>
                <div className="speak_line-res-horse">
                    {this.props.entry.hnb} - {this.props.entry.horse}
                </div>
            </Col>
            <Col xs={3} className="speak_line-res-points speak_alignRight">
                <div className="speak_line-res-pointsTxt">
                    <p>{this.props.entry.results.totalPen?this.props.entry.results.totalPen:<>&nbsp;</>}{this.props.entry.results.totalTime && <> / {this.props.entry.results.totalTime}</>}</p>
                </div>
                <div className="speak_line-res-pointsFlag">
                    {this.props.entry.nation && <img alt="drapeau" width="70px" src={"/img/flags/"+this.props.entry.nation+'.png'}/>}
                </div>
            </Col>
        </Row>
        :null}
        </>
)
}
}
export default TableLine;

import React, { useState, useEffect, useRef } from "react";
import Badge from 'react-bootstrap/Badge';
import {applyFontStretch} from '../../functions/strech.js'

function GiantRanking(props) {

    const [ stylePath, setStylePath ] = useState(null);
    useEffect(() => {    
        if (stylePath){
            var head = document.head;
            var link = document.createElement("link");
        
            link.type = "text/css";
            link.rel = "stylesheet";
            link.href = stylePath;
        
            head.appendChild(link);    
            console.log(link)
            return () => { head.removeChild(link); }
        }
        
      }, [stylePath]);
    useEffect(() => {
    console.log('specific css '+props.specificCSS)
    if (props.specificCSS){
        var urlCSS = '/img/events/'+idArena+'/giant/giant_specific.css'
        setStylePath(urlCSS)
    }
    }, [props.specificCSS]);

    if (props.specificGFX){
        var urlGFX = '/img/events/'+idArena+'/giant/'
    } else {
        var urlGFX = '/img/giant/'
    }     

    if (props){
        var idArena = props.idArena;
        var datas = props.datas;        
        var datasDisplayList = props.datasDisplayList;
        if (props.specificGFX){
            var urlGFX = '/img/events/'+idArena+'/giant/'
        } else {
            var urlGFX = '/img/giant/'
        }   
    }

    if (props.specificFlag){
        var urlFlag = '/img/events/'+idArena+'/giant/flags/'
    } else {
        var urlFlag = "/img/flags/"
    }  

    useEffect(() => {
        applyFontStretch(".giant-ranking-title-text")
    },[datas])
    return (
        <>
            <div style={{ backgroundColor: "transparent",height:'100%',width:'100%',backgroundImage: "url("+urlGFX+"scb-ranking-fond.png)"}}>
                {datas.ranking &&
                <>
                <div className="giant-ranking-title" style={{ backgroundImage: "url("+urlGFX+"scb-ranking-title.png)"}}>
                    <div className="giant-ranking-title-text">
                        <p>{datas.competition.nameTV}</p>
                    </div>
                </div>
                <div className="giant-ranking-lines">
                    {(datasDisplayList && datasDisplayList.list)&& datasDisplayList.list.map((d,index)=>{
                        if (index < 5){
                            return(
                                <div key={index} className="giant-ranking-line" style={{ backgroundRepeat:'no-repeat',backgroundImage: "url("+urlGFX+"scb-ranking-line.png)"}}>
                                    <div className="giant-ranking-line-rank">
                                        {/* {d.results.entryRank}. */}{d.entryRank}
                                    </div>
                                    <div className="giant-ranking-line-rider">
                                        {d.rider}
                                    </div>
                                    <div className="giant-ranking-line-horse">
                                        {d.horse}
                                    </div>
                                    <div className="giant-ranking-line-res">
                                        {/* {d.results.totalPen}{d.results.totalTime && <> / {d.results.totalTime}</>} */}
                                        {d.res}
                                    </div>
                                    <div className="giant-ranking-flag">
                                        {d.nation &&
                                            <img alt="drapeau" className="giant-ranking-drapeau" src={urlFlag+d.nation+'.png'}/>
                                        }
                                    </div>

                                </div>        
                            )        
                        } else {
                            return (null)
                        }
                    })}
                </div> 
                </>   
                }    
            </div>
        </>
      );
  }

  export default GiantRanking;

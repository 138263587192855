import React, { useState, useEffect, useRef } from "react";
import Badge from 'react-bootstrap/Badge';

function GiantSo01(props) {
    if (props){
        var idArena = props.idArena;
        var datas = props.datas;
        var datasTiming = props.datasTiming;
    }

    //var urlBackground = "url(/img/events/"+idArena+".png)"
    //var urlBackground = "url(/img/giant_full/fond.png)"
    if (props.specificGFX){
        var urlGFX = '/img/events/'+idArena+'/giant_shf/'
    } else {
        var urlGFX = '/img/giant_shf/'
    }     
    return (
        <>
            {/* <div style={{ backgroundImage: 'url('+urlGFX+'fond.jpg)',height:'100%',width:'100%'}}> */}
            <div style={{ backgroundImage: 'url('+urlGFX+'fond.jpg)',height:'100%',width:'100%'}}>
                {datas.live &&
                <>
                <div className="giant_shf-rider">
                    {datas.live.rider}
                </div>
                <div className="giant_full-flag">
                    {datas.live.nation &&
                        <img alt="drapeau" width="250px" src={"/img/flags/"+datas.live.nation+'.png'}/>
                    }
                </div>
                {/* <div className="giant_full_line1">&nbsp;</div>
                <div className="giant_full_line2">&nbsp;</div> */}
                <div className="giant_shf-pere">
                    {datas.live.sire && 'Sire: '+datas.live.sire}
                </div>
                <div className="giant_shf-mere">
                    {datas.live.dam && 'Dam: '+datas.live.dam}
                </div>
                <div className="giant_shf-grandpere">
                    {datas.live.dam && 'Sire of Dam: '+datas.live.siredam}
                </div>

                <div className="giant_shf-num">
                    {datas.competition.isInter ? datas.live.order:datas.live.hnb}
                </div>
                <div className="giant_shf-horse">
                    {datas.live.horse}
                </div>
                <div className="giant_shf-race">
                    {datas.live.race}
                </div>
                <div className="giant_shf-chrono" style={{ backgroundImage: 'url('+urlGFX+'chrono.png)'}}>
                    <div className="giant_shf-chrono-txt">
                        {datas.live.results.totalPen == 'RET' || datas.live.results.totalPen == 'EL' ?<></>:<>{datasTiming.countDown ? datasTiming.countDownValue : datasTiming.time}</>}
                    </div>
                </div>
                <div className="giant_shf-points" style={{ backgroundImage: 'url('+urlGFX+'points.png)'}}>
                    <div className="giant_shf-points-txt">
                        {datas.live.results.totalPen == 'RET' || datas.live.results.totalPen == 'EL' ?<>{datas.live.results.totalPen}</>:<>{datasTiming.totalFaults}</>}
                    </div>
                </div>
                {/* <div className="giant_full-rank">
                    {datas.live.results.extraPoints ?
                    <>
                        {datas.live.results.extraPoints}
                    </>:<>
                    {datas.live.results.entryRank ? <>{'Rank: '+datas.live.results.entryRank+'.'}</>:null}</>}
                </div>
                <div className="giant_full-ecart">
                    {datas.timeDiff && 
                        <>
                        {datas.timeDiff > 0 ? 
                        <><Badge bg="danger" style={{paddingTop:'10px',paddingBottom:'10px'}}>{'+'+datas.timeDiff}</Badge></>
                        :
                        <><Badge bg="success" style={{paddingTop:'10px',paddingBottom:'10px'}}>{datas.timeDiff}</Badge></>} 
                        </>
                    }
                </div> */}
                </>
                }
            </div>
        </>
      );
  }

  export default GiantSo01;
